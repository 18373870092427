/* eslint-disable @typescript-eslint/member-ordering */
/**
 *
 */
export type OrNull<T> = T | null;

/**
 *
 */
export type Opt<T> = T | undefined;

/**
 * ensure provides a runtime check ensuring that the value {@code val} is not
 * null or undefined.
 *
 * @param val the value to be ensured.
 * @returns the value passed as val.
 * @throws an Error if the provided value is null or undefined.
 */
export const ensure = <T>(val: Opt<T>): T => {
    if (val === undefined || val === null || typeof val === "undefined") {
        throw new Error("value is undefined or null");
    }
    return val;
};

/**
 *
 */
export class Optional<T> {

    /**
     * empty returns an empty Optional.
     */
    public static empty<T>(): Optional<T> {
        return Optional.EMPTY as Optional<T>;
    }

    /**
     *
     * @param value
     */
    public static of<T>(value: T): Optional<T> {
        return new Optional<T>(value);
    }

    /**
     *
     * @param value
     */
    public static ofOpt<T>(value: Opt<T>): Optional<T> {
        return Optional.isNull(value) ? Optional.empty() : Optional.of(value as T);
    }

    /**
     * EMPTY is the singular empty instance field representing a null or
     * undefined value.
     */
    private static EMPTY = new Optional<{}>(undefined);

    /**
     *
     * @param v
     */
    private static isNull<T>(v: Opt<T>): boolean {
        return v === null || v === undefined || typeof v === "undefined";
    }

    /**
     *
     * @param value
     */
    private constructor(private readonly value: Opt<T>) { }

    /**
     *
     */
    public get(): T {
        return ensure(this.value);
    }

    /**
     *
     */
    public getOpt(): Opt<T> {
        return this.value;
    }

    /**
     *
     */
    public isPresent(): boolean {
        return !Optional.isNull(this.value);
    }

    /**
     *
     * @param consumer
     */
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public ifPresent(consumer: (v: T) => void) {
        if (!Optional.isNull(this.value)) {
            consumer(this.get());
        }
    }

    /**
     *
     * @param predicate
     */
    public filter(predicate: (v: T) => boolean): Optional<T> {
        if (!this.isPresent()) {
            return this;
        }

        return !!predicate(this.get())
            ? this
            : Optional.empty();
    }

    /**
     *
     * @param mapper
     */
    public map<U>(mapper: (v: T) => U): Optional<U> {
        if (!this.isPresent()) {
            return Optional.empty();
        }
        return Optional.ofOpt(mapper(this.get()));
    }

    /**
     *
     * @param mapper
     */
    public flatMap<U>(mapper: (v: T) => Optional<U>): Optional<U> {
        if (!this.isPresent()) {
            return Optional.empty();
        }
        return ensure(mapper(this.get()));
    }

    /**
     *
     * @param v
     */
    public orElse(v: T): T {
        return this.isPresent() ? this.get() : v;
    }

    /**
     *
     * @param supplier
     */
    public orElseGet(supplier: () => T): T {
        return this.isPresent() ? this.get() : supplier();
    }

    /**
     *
     * @param supplier
     */
    public orElseThrow<E extends Error>(supplier: () => E): T {
        if (!this.isPresent()) {
            throw supplier();
        }
        return this.get();
    }

    /**
     *
     */
    public toString(): string {
        return Optional.isNull(this.value)
            ? "Optional.empty"
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            : `Optional[${this.value}]`;
    }
}
