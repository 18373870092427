import * as React from "react";

import { ThemeConsumerProps, ThemeProviderProps } from "../../../theme/_Types";
import { joinClasses } from "../../../utils/Filters";
import { Div } from "./Div";

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    theme?: ThemeProviderProps;
}

/**
 *
 * @param props
 */
export const Container: React.FC<Props> = (props) => {
    const clazzName = joinClasses(
        props.className || "",
        "scl-b-container",
    );

    return !props.children
        ? null
        : (
            <Div {...props} className={clazzName}>
                {props.children}
            </Div>
        );
};
