/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";

import BrowserImage from "../assets/images/libs/core/browsers.png";
import NewVersion from "../assets/images/libs/core/newversion.png";
import { Div } from "../components/core/03-base/Div";
import { ModalNotification } from "../components/core/09-views/03-modals/Notification";
import { ThemePalette } from "../theme/_Types";
import * as I18n from "./translations/I18n";

declare global {
    interface Document {
        documentMode?: any;
    }
}

/**
 *
 */
interface OwnProps {
}

/**
 *
 */
interface StateProps {
}


export interface DispatchProps {
    handleBrowserNotification: (message: string) => void;
    handleNewAppVersion: (message: string) => void;
    closeModal: (id: string) => void;
}

interface ModalProps {
    id: string;
    onClose: () => void;
    message: string;
}

export const ModalGenericWarning: React.FunctionComponent<ModalProps> = (props) => (
    <ModalNotification
        id={props.id}
        onCloseModal={props.onClose}
        message={props.message}>
        <React.Fragment>
            <Div>
                <img
                    src={BrowserImage}
                    alt="IE xD!"
                    style={{
                        maxWidth: "75px",
                        alignSelf: "center",
                        margin: "0 auto",
                        display: "block",
                        transform: "translateX(15px)",
                    }}
                />
            </Div>
            <div className="scl-b-h5 scl-b-h">
                {I18n.getText("mary.utils.verifycontext.browser-support",
                    "Browser support")}
            </div>
            <br />
            <p>
                {props.message}
            </p>
        </React.Fragment>
    </ModalNotification>
);

export const ModalNewVersionAvailable: React.FunctionComponent<ModalProps> = (props) => (
    <ModalNotification
        id={props.id}
        title={I18n.getText("mary.utils.verifycontext.newversion-title",
            "Notification")}
        onCloseModal={props.onClose}
        message={props.message}
        theme={{
            palette: ThemePalette.BRAND_ACCENT,
        }}
    >
        <React.Fragment>
            <Div>
                <img
                    src={NewVersion}
                    alt="New App. Version"
                    style={{
                        maxWidth: "150px",
                        alignSelf: "center",
                        margin: "0 auto",
                        display: "block",
                        transform: "translateX(15px)",
                    }}
                />
            </Div>
            <div className="scl-b-h5 scl-b-h">
                {I18n.getText("mary.utils.verifycontext.newversion-description",
                    "New version available!")}
            </div>
            <br />
            <p>
                {props.message}
            </p>
        </React.Fragment>
    </ModalNotification>
);

const checkBrowsers = (handleBrowserNotification: (message: string) => void) => {
    const isIe = false || !!document.documentMode;

    if (isIe) {
        handleBrowserNotification(I18n.getText("mary.utils.verifycontext.browsersupport-description",
            `We don't actively support this browser, because of security and UI/UX reasons. \n \n
            Please use one of the more modern browers like Chrome, Edge, Firefox or Safari.`) ?? "",
        );
    }
};

const semverGreaterThan = (versionA: string, versionB: string) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());
        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) {
            continue;
        }
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
};

const checkAppVersion = (handleBrowserNotification: (message: string) => void) => {
    const isIe = false || !!document.documentMode;

    if (!isIe) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        fetch(`${process.env.PUBLIC_URL ?? ""}/meta.json?${new Date().getTime()}`, { cache: "no-cache" })
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                // eslint-disable-next-line @typescript-eslint/no-var-requires
                const packageJson = require("../../package.json");

                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                const shouldForceRefresh = semverGreaterThan(latestVersion, packageJson.version);
                if (shouldForceRefresh) {
                    const notificationMessage = I18n.getText("mary.utils.verifycontext.newversionavailable-message",
                        "There is a new version ({{version}}) of the {{app-name}} application. You should refresh to get the latest features and/or bugfixes.",
                        {"version": latestVersion, "app-name": process.env.REACT_APP_TITLE ?? "Application"}) ?? "";

                    console.log(notificationMessage);
                    handleBrowserNotification(notificationMessage);
                } else {
                    console.log(I18n.getText("mary.utils.verifycontext.version-message",
                        "{{app-name}} by Claude is running the latest version: v{{version}}",
                        {"version": latestVersion, "app-name": process.env.REACT_APP_TITLE ?? "Application"}));
                }
            });
    }
};

/**
 *
 */
export type Props = React.PropsWithChildren<OwnProps & StateProps & DispatchProps>;

/**
 * VerifyContext implements Claude's root component.
 */
export class VerifyContext
    extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
    }

    public componentDidMount() {
        checkBrowsers(this.props.handleBrowserNotification);
        checkAppVersion(this.props.handleNewAppVersion);
    }

    public render() {
        return this.props.children;
    }
}

export const testUtil = {
    checkBrowsers: checkBrowsers,
    genericWarningModal: ModalGenericWarning,
    newVersionAvailableModal: ModalNewVersionAvailable,
    semverGreaterThan: semverGreaterThan,
    checkAppVersion: checkAppVersion,
};
