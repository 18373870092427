/* eslint-disable */
import * as React from "react";
import { SwipeEventData, useSwipeable } from "react-swipeable";

interface Props {
    detectSwipeDir: (dir: string) => void;
}

export const SwipeBlock: React.FC<Props> =
    (props) => {
        const handlers = useSwipeable({
            onSwipedLeft: (event: SwipeEventData) => {
                props.detectSwipeDir(event.dir);
            },
            onSwipedRight: (event: SwipeEventData) => {
                props.detectSwipeDir(event.dir);
            },
            preventDefaultTouchmoveEvent: true,
            trackMouse: true,
        });

        return (
            <div {...handlers} >
                {props.children}
            </div>
        );
    };
