import * as React from "react";

import { ThemeConsumerProps } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { joinClasses } from "../../../utils/Filters";

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    transparent?: boolean;
}

/**
 *
 * @param props
 */
const DivComp: React.FunctionComponent<Props> = (props) => {
    const clazzName = joinClasses(
        props.transparent === true ? undefined : "scl-h-background",
        props.className,
    );

    return (
        <div className={clazzName} style={props.style} >
            {props.children}
        </div>
    );
};

/**
 *
 */
export const Div = asThemeConsumer(DivComp, undefined);
