import React from "react";
import { Container } from "../../03-base/Container";

import { LostAndFound } from "../00-blocks/LostAndFound";
import { Page } from "../01-sections/Page";
import * as I18n from "../../../../utils/translations/I18n";

interface Props {
    title?: string;
    description?: string;
}

export const NoMatchingRoute: React.FC<Props> = (props) => (
    <Page>
        <Container
            theme={{
                padding: {
                    "": { y: 3 },
                    "sm": { y: 4 },
                },
            }}
        >
            <LostAndFound
                title={props.title ?? I18n.getText("mary.09-views.02-pages.nomatchingroute.title",
                    "Are you lost? (404) ") ?? ""}
                description={props.description ?? I18n.getText("mary.09-views.02-pages.nomatchingroute.description",
                    "Well, Claude certainly is.") ?? ""}
            />
        </Container>
    </Page>
);
