import * as React from "react";
import { ThemeConsumerProps } from "../../../theme/_Types";
import { joinClasses } from "../../../utils/Filters";
import { asThemeConsumer } from "../../../theme/Theme";


export enum DropdownPositionX {
    LEFT = "left",
    RIGHT = "right",
    CENTER = "center",
}

export enum DropdownPositionY {
    TOP = "top",
    BOTTOM = "bottom",
}

export enum DropdownVariant {
    MENU = "menu",
}

interface State {
    show: boolean;
    dropdownHoverTimeout?: NodeJS.Timeout;
}

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    positionX?: DropdownPositionX;
    positionY?: DropdownPositionY;
    trigger?: React.ReactNode;
    content: React.ReactNode;
    variant?: DropdownVariant;
    manual?: boolean;
    show?: boolean;
}

export class DropdownComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            show: false,
            dropdownHoverTimeout: undefined,
        };

        this.onShow = this.onShow.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    /**
     *
     */
    public render(): JSX.Element {
        const clazzName = joinClasses(
            this.props.className || "",
            "scl-o-dropdown__trigger",
            this.isManual()
                ? (this.props.show === true
                    ? "scl-o-dropdown--open" : "scl-o-dropdown--closed") :
                (this.state.show
                    ? "scl-o-dropdown--open" : "scl-o-dropdown--closed"),
        );

        /* The variant is Menu by default */
        const currentVariant = this.props.variant === DropdownVariant.MENU
            ? "scl-o-dropdown__menu" : "scl-o-dropdown__menu";

        const classNameDropdown = joinClasses(
            currentVariant,
            this.props.positionX
                ? currentVariant + "--" + this.props.positionX : undefined,
            this.props.positionY
                ? currentVariant + "--" + this.props.positionY : undefined,
        );

        return (
            <div
                className={clazzName}
                onMouseOver={!this.isManual() ? this.onShow : undefined}
                onMouseOut={!this.isManual() ? this.onHide : undefined}
            >
                {this.props.trigger}
                <div className={classNameDropdown}>
                    {this.props.content}
                </div>
            </div>
        );
    }

    private onShow = (_evt: React.MouseEvent) => {
        if (this.state.dropdownHoverTimeout) {
            clearTimeout(this.state.dropdownHoverTimeout);
        }
        this.setState({
            show: true,
        });
    };

    private onHide = (_evt: React.MouseEvent) => {
        if (this.state.dropdownHoverTimeout) {
            clearTimeout(this.state.dropdownHoverTimeout);
        }
        this.setState({dropdownHoverTimeout: setTimeout(() => {
            this.setState({
                show: false,
            });
        }, 300),
        });
    };

    private isManual(): boolean {
        return !(this.props.show === undefined || typeof this.props.show === "undefined");
    }
}

/**
 *
 */
export const Dropdown = asThemeConsumer(DropdownComp);
