/* eslint-disable max-len */
import * as React from "react";

import {
    ThemeBreakpoints,
    ThemePalette,
    ThemePaletteState,
    ThemeProviderProps,
    ThemeSize,
} from "../../../../theme/_Types";
import { refreshMary } from "../../../../utils/General";
import * as I18n from "../../../../utils/translations/I18n";
import { Container } from "../../03-base/Container";
import { Div } from "../../03-base/Div";
import { FlexColumn } from "../../03-base/Flex/Column";
import { FlexRow } from "../../03-base/Flex/Row";
import { FillUp } from "../../04-helpers/FillUp";
import { Button } from "../../05-atoms/Button";
import { IconNames } from "../../05-atoms/Icon";
import { Alignment, ButtonsWrapper, Orientation } from "../../06-molecules/ButtonsWrapper";
import { Modal } from "../../06-molecules/Modal";
import { Navbar } from "../../07-organisms/Navbar";
import { NavbarIcon } from "../../07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackContent, NavbarStackRight } from "../../07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../07-organisms/Navbar/Title";

interface OwnProps {
    title?: string;
    message?: string;
    theme?: ThemeProviderProps;
    refreshButton?: boolean;
    id: string;
    onCloseModal: () => void;
}

type Props = OwnProps;

export const ModalNotification: React.FunctionComponent<Props> = (props) =>  (
    <Modal
        modalID={props.id}
        theme={{ size: props?.theme?.size ?? ThemeSize.SMALL }}
    >
        <FlexRow isFixed>
            <Navbar
                theme={props.theme ? (props.theme) : {
                    paletteState: ThemePaletteState.WARNING,
                }}
            >
                <NavbarStack>
                    <NavbarStackContent>
                        <NavbarTitle>
                            {props.title ? props.title :
                                I18n.getText("mary.09-views.03-modals.notification.title", "Warning")}
                        </NavbarTitle>
                    </NavbarStackContent>
                    <NavbarStackRight>
                        <NavbarIcon>
                            <Button
                                className={"scl-o-navbar__icon"}
                                icon={IconNames.TIMES}
                                link={{ onClick: props.onCloseModal }}/>
                        </NavbarIcon>
                    </NavbarStackRight>
                </NavbarStack>
            </Navbar>
        </FlexRow>
        <FlexRow
            theme={{
                palette: ThemePalette.CONTRAST_PRIMARY,
            }}
            isScrollable
        >
            <FillUp>
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                            "md": { y: 4 },
                        },
                    }}
                >
                    {props.children ?
                        props.children : (
                            <>
                                <div className="scl-b-h5 scl-b-h">
                                    {I18n.getText("mary.09-views.03-modals.notification.message-label",
                                        "Message:")}
                                </div>
                                <br />
                                <p>
                                    {props.message ??
                                        I18n.getText("mary.09-views.03-modals.notification.message-fallback",
                                            "No message.")}
                                </p>
                            </>
                        )
                    }
                </Container>
            </FillUp>
        </FlexRow>
        <FlexRow isFixed>
            <FlexColumn
                theme={{
                    palette: ThemePalette.CONTRAST_SECONDARY,
                }}
            >
                <Container
                    theme={{
                        padding: {
                            "": { y: 3 },
                        },
                    }}
                >
                    <ButtonsWrapper
                        orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL}}
                        alignment={Alignment.RIGHT}>
                        {props.refreshButton &&
                            <Div style={{display: "flex"}}>
                                <Button
                                    theme={{
                                        palette: ThemePalette.CONTRAST_SECONDARY,
                                    }}
                                    link={{ onClick: refreshMary }}
                                >
                                    {I18n.getText(
                                        "mary.09-views.refresh-btn",
                                        "Refresh the page")}
                                </Button>
                            </Div>
                        }
                        <Div style={{display: "flex"}}>
                            <Button
                                theme={{
                                    palette: ThemePalette.BRAND_ACCENT,
                                }}
                                link={{ onClick: props.onCloseModal}}
                            >
                                {I18n.getText(
                                    "mary.close", "Close")}
                            </Button>
                        </Div>
                    </ButtonsWrapper>
                </Container>
            </FlexColumn>
        </FlexRow>
    </Modal>
);

