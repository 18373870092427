import React from "react";
import { ThemePalette } from "../../../../theme/_Types";
import { FlexRow } from "../../03-base/Flex/Row";
import { FillUp } from "../../04-helpers/FillUp";
import * as OAS from "../../../../utils/OAS3";

export interface PageType {
    title: string;
    description: string;
    path: string;
    roles: string[];
    component?: React.FunctionComponent | React.ComponentState;
    beta?: boolean;
    parameters?: OAS.Parameter[];
    pagination?: boolean;
    responses?: OAS.Responses;
    parent?: PageType;
}

export const Page: React.FC<{}> = (props) => (
    <React.Fragment>
        <FlexRow isScrollable>
            <FillUp
                theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}
            >
                {props.children}
            </FillUp>
        </FlexRow>
    </React.Fragment>
);
