import React from "react";
import { BreakpointConsumer } from "../../../utils/Breakpoints";
import { joinClasses } from "../../../utils/Filters";
import { Props as OrientationProps, getOrientation } from "../../../utils/Orientation";

export enum Orientation {
    HORIZONTAL = "horizontal",
    VERTICAL = "vertical",
}

export enum Alignment {
    LEFT = "left",
    RIGHT =  "right",
    CENTER = "center",
    SPACE_AROUND = "space-around",
    SPACE_BETWEEN = "space-between",
}

export type Props = OrientationProps & {
    alignment?: Alignment;
    asGroup?: boolean;
};

/**
 *
 * @param or
 */
export const getOrientClass = (or?: Orientation) =>
    !!or ? `scl-m-button-wrapper--orientation-${or}` : undefined;

export const getAlignmentClass = (alignment?: Alignment) =>
    !!alignment ? `scl-m-button-wrapper--alignment-${alignment}` : undefined;

/**
 *
 * @param props
 */
export const ButtonsWrapper: React.FunctionComponent<Props> = props => (
    <BreakpointConsumer>
        {(value) => (
            <div
                className={joinClasses(
                    "scl-m-button-wrapper",
                    props.asGroup ? "scl-m-button-wrapper--as-group" : undefined,
                    getOrientClass(getOrientation(value, props.orientations)
                        ?? props.defaultOrientation ?? Orientation.VERTICAL),
                    getAlignmentClass(props.alignment || Alignment.CENTER),
                )}
            >
                {props.children}
            </div>
        )}
    </BreakpointConsumer>
);
