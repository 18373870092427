/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { I18n } from "@aws-amplify/core";
import * as React from "react";

import { ThemeConsumerProps, Themes } from "../../../theme/_Types";
import { joinClasses } from "../../../utils/Filters";
import { I18nLanguage } from "../../../utils/translations/I18n";

/**
 * misuse is thrown when FlexWrapper is consumed without a valid provider being
 * present.
 */
const misuse = new Error("misuse error: no BaseProvider in ancestors");

/**
 *
 *
 */
interface Props
    extends ThemeConsumerProps {
    theme: Themes;
    language?: I18nLanguage;
    refresh?: boolean;
    /** Whenever you use this you need to make sure that all themes are built to the public assets folder */
    dynamic?: boolean;
}

/**
 *
 */
interface State {
    activeTheme: Themes;
    activeLanguage: I18nLanguage;
}

/**
 * BaseBinding are properties used by consuming components that wish to
 * interact with the state of the Base.
 */
export interface BaseBinding {
    /**
     * setTheme allows the consumer to update the current theme priority.
     */
    setTheme: (to: Themes) => void;
    /**
     * getTheme allows the consumer to query the current theme priority.
     */
    getTheme: () => Themes;

    setLanguage: (to: I18nLanguage) => void;
    getLanguage: () => I18nLanguage;
    themeIsLoaded: () => Promise<string>;

}
/**
 * BaseBindingInit
 */
export const baseBindingInit:
() => BaseBinding =
    () => ({
        setTheme: (_to) => {
            throw misuse;
        },
        getTheme: () => {
            throw misuse;
        },
        setLanguage: (_to) => {
            throw misuse;
        },
        getLanguage: () => {
            throw misuse;
        },
        themeIsLoaded: () => {
            throw misuse;
        },
    });

/**
 *
 */
const BaseContext = React.createContext<BaseBinding>(baseBindingInit());

/**
 * BaseProvider is the Provider component of the Base component.
 */
export class BaseProvider
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.state = {
            activeTheme: props.theme,
            activeLanguage: props.language ?? I18nLanguage.ENGLISH,
        };

        I18n.setLanguage(this.state.activeLanguage);

        this.setTheme = this.setTheme.bind(this);
        this.setThemeReferences = this.setThemeReferences.bind(this);
        this.getTheme = this.getTheme.bind(this);
        this.setLanguage = this.setLanguage.bind(this);
        this.getLanguage= this.getLanguage.bind(this);
        this.isThemeLoaded = this.isThemeLoaded.bind(this);
        this.getStyleSheet = this.getStyleSheet.bind(this);
        this.themeIsLoaded = this.themeIsLoaded.bind(this);
    }

    /**
     *
     */
    public render() {
        const setTheme = this.setTheme;
        const getTheme = this.getTheme;
        const setLanguage = this.setLanguage;
        const getLanguage = this.getLanguage;
        const themeIsLoaded = this.themeIsLoaded;

        const clazzName = joinClasses(
            "scl-b",
            this.props.className,
        );
        return (
            <BaseContext.Provider
                value={{
                    setTheme: setTheme,
                    getTheme: getTheme,
                    setLanguage: setLanguage,
                    getLanguage: getLanguage,
                    themeIsLoaded: themeIsLoaded,
                }}
            >
                <div className={clazzName} style={this.props.style} >
                    {this.props.children}
                </div>
            </BaseContext.Provider>
        );
    }

    public componentDidUpdate(prevProps: Props) {
        if (prevProps.theme !== this.props.theme) {
            this.setTheme(this.props.theme);
            if (this.props.refresh) {
                window.location.reload();
            }
        }

        if (this.props.language && (prevProps.language !== this.props.language)) {
            this.setLanguage(this.props.language);

        }
    }

    private getStyleSheet(unique_title: string) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for(let i=0; i<document.styleSheets.length; i++) {
            const sheet = document.styleSheets[i];
            if(sheet.title === unique_title) {
                return sheet;
            }
        }
        return undefined;
    }

    private checkIfLoaded(val?: string) {
        if (val) {
            // eslint-disable-next-line max-len
            return val.includes(".scl-preloader__wrapper:not(.scl-preloader__wrapper-forcedshow)") ? val : undefined;
        }
        return undefined;
    }

    private isThemeLoaded(): boolean {
        let returnVal = false;
        const ruleList = this.getStyleSheet("meta-css")?.cssRules;
        if(ruleList) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            for (const rule of ruleList) {
                if (this.checkIfLoaded(rule.cssText)) {
                    returnVal = true;
                    break;
                }
            }
        }
        return returnVal;
    }

    private themeIsLoaded(): Promise<string> {
        return new Promise((resolve, reject) => {
            let retries = 0;
            const checkIsThemeLoaded = () => {
                if(this.isThemeLoaded()) {
                    resolve("success");
                } else {
                    if(retries < 30) {
                        setTimeout(() => checkIsThemeLoaded(), 200 + (retries * 100));
                        retries += 1;
                    } else {
                        reject("error");
                    }
                }
            };
            checkIsThemeLoaded();
        });
    }

    /**
     * setTheme implements the setTheme method for the BaseBinding
     * provided to the Consumers.
     *
     * @param to the new theme priority.
     */
    private setTheme(to: Themes) {
        this.setState({
            activeTheme: to || this.props.theme,
        }, () => {
            if(this.props.dynamic) {
                this.setThemeReferences();
            }
        });
    }

    private setThemeReferences() {
        const brand = this.state.activeTheme;
        const metaCss = document.getElementById("meta-css");
        if (metaCss) {
            metaCss.setAttribute("href", "/assets/stylesheets/themes/"+ brand + "/index.css");
        }

        const metaThemeColor = document.getElementById("meta-theme-color");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", brand === Themes.DUTCHLEASE
                ? "#000" : brand === Themes.MAN ? "#303c49" : "#004666" );
        }

        const metaThemeConfig = document.getElementById("meta-theme-config");
        if (metaThemeConfig) {
            metaThemeConfig.setAttribute("content", "/assets/images/themes/"+ brand + "/icons/browserconfig.xml" );
        }

        const metaFavicon = document.getElementById("meta-favicon");
        if (metaFavicon) {
            metaFavicon.setAttribute("href", "/assets/images/themes/"+ brand + "/icons/favicon.ico" );
        }

        const metaFaviconApple = document.getElementById("meta-favicon-apple");
        if (metaFaviconApple) {
            metaFaviconApple.setAttribute("href", "/assets/images/themes/"+ brand + "/icons/apple-touch-icon.png" );
        }

        const metaFavicon32 = document.getElementById("meta-favicon-32");
        if (metaFavicon32) {
            metaFavicon32.setAttribute("href", "/assets/images/themes/"+ brand + "/icons/favicon-32x32.png" );
        }

        const metaFavicon16 = document.getElementById("meta-favicon-16");
        if (metaFavicon16) {
            metaFavicon16.setAttribute("href", "/assets/images/themes/"+ brand + "/icons/favicon-16x16.png" );
        }
    }

    /**
     * getTheme implements the getFocus method for the BaseBinding
     * provided to the Consumers.
     *
     * @return the currently active theme priority.
     */
    private getTheme(): Themes {
        return this.state.activeTheme;
    }


    private setLanguage(to: I18nLanguage) {
        this.setState({
            activeLanguage: to || this.props.language,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        }, () => I18n.setLanguage(this.state.activeLanguage),
        );
    }

    private getLanguage(): I18nLanguage {
        return this.state.activeLanguage;
    }
}

/**
 * BaseConsumer is the Consumer component of the Base component.
 */
export const BaseConsumer = BaseContext.Consumer;
