import * as React from "react";
import { ReactDatePickerProps } from "react-datepicker";
import { ThemeConsumerProps } from "../../../../theme/_Types";
import { asThemeConsumer } from "../../../../theme/Theme";
import { Input } from "../../05-atoms/Input";
import { Select, SelectOption } from "../../05-atoms/Select";
import { ValidationFeedback, fieldValidationIcon, fieldValidationMessage } from "./Field.utils";
import { FormGroup } from "./Group";
import { Div } from "../../03-base/Div";
import { LoadingIndications, LoadingIndicator } from "../../05-atoms/LoadingIndicator";

export interface FieldLabelValue {
    label: string;
    value?: string;
}

export interface FieldValueProps {
    options?: SelectOption[];
    value?: string | number;
    values?: string[] | SelectOption[];
    type?: string;
    min?: number | string;
    max?: number | string;
    minDate?: Date;
    maxDate?: Date;
    multiple?: boolean;
}

export interface FieldValidationProps {
    validationFunction?: (value: string | number) => ValidationFeedback;
    readonly?: boolean;
    disabled?: boolean;
    required?: boolean;
}

interface FieldProps extends Pick<ReactDatePickerProps, "popperPlacement"> {
    label?: string;
    placeholder?: string;
    onChange?: (value?: string | number) => void;
    onChangeFilter?: (inputValue?: string, callback?: (updatedOptions: SelectOption[]) => void) => void;
    asSummary?: boolean;
    isLoading?: boolean;
    withFilters?: boolean;
}

/**
 *
 */
type Props = ThemeConsumerProps &
FieldProps &
FieldValidationProps &
FieldValueProps;

/**
 *
 * @param props
 */
const FormFieldComp: React.FunctionComponent<Props> = (props) => (
    <FormGroup {...props}>
        {props.isLoading ? (
            <Div style={{height: "63px", justifyContent: "center", alignItems: "center", flex: 1}}>
                <LoadingIndicator type={LoadingIndications.DEFAULT} />
            </Div>
        ) :
            (!props.options && !props.onChangeFilter)
                ?
                <Input
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={!!props.readonly ? undefined : props.onChange}
                    type={props.type || "text"}
                    disabled={!!props.readonly ? true : props.onChange ? false : true}
                    className={props.asSummary ? "scl-a-input__field--as-summary" : ""}
                    min={props.min}
                    max={props.max}
                    required={props.required}
                    asSummary={props.asSummary}
                    popperPlacement={props.popperPlacement}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                />
                : <Select
                    required={props.required}
                    placeholder={props.placeholder}
                    value={props.value}
                    values={props.values}
                    onChange={!!props.readonly ? undefined : props.onChange}
                    options={props.options || []}
                    defaultValue={props.value as string | undefined}
                    disabled={!!props.readonly ? true : props.onChange ? false : true}
                    multiple={props.multiple}
                    withFilter={props.withFilters}
                    onChangeFilter={props.onChangeFilter}
                />}
        {fieldValidationMessage(props) &&
                (
                    <div className="scl-o-form__info">
                        <div className="scl-o-form__info-text">
                            {fieldValidationMessage(props)}
                        </div>
                        <div className="scl-o-form__info-icon">
                            {fieldValidationIcon(props)}
                        </div>
                    </div>
                )
        }
    </FormGroup>
);

/**
 *
 */
export const FormField = asThemeConsumer(FormFieldComp);
