import * as React from "react";

import { Dropdown, DropdownVariant } from "./Dropdown";
import { NavLink } from "react-router-dom";
import { LinkProps, LinkPropsOnClick } from "../05-atoms/Link";
import { ThemeConsumerProps } from "../../../theme/_Types";
import { joinClasses } from "../../../utils/Filters";
import { ComingSoon } from "../09-views/00-blocks/ComingSoon";
import { asThemeConsumer } from "../../../theme/Theme";
import { ToggleMenuItems } from "./ToggleMenuItems";

export enum MenuOrientation {
    VERTICAL = "vertical",
    HORIZONTAL = "horizontal",
}

export interface MenuTitle {
    children?: React.ReactNode;
}

export interface MenuItem {
    items?: MenuItem[];
    navLink?: string;
    children?: React.ReactNode;
    disabled?: boolean;
    link?: LinkProps;
    active?: boolean;
    beta?: boolean;
}

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    orientation: MenuOrientation;
    title?: MenuTitle;
    items?: MenuItem[];
    collapsable?: boolean; // For now only for horizontal menu
}

const getLinkContent = (item: MenuItem, exact?: boolean): JSX.Element => {
    const itemClazzName = joinClasses(
        item.active ? "scl--active" : undefined,
        item.disabled ? "scl--disabled" : undefined,
    );

    return (
        item?.navLink ?
            (
                <NavLink
                    to={item.navLink || ""}
                    activeClassName={"scl--active"}
                    exact={item.navLink === "/" || exact}
                    onClick={item?.link ? (item.link as LinkPropsOnClick).onClick : undefined}
                >
                    {item.children}{item.items && <span className="scl-h-text--tiny">&nbsp;({item.items.length})</span>}
                    {!!item.beta && <ComingSoon />}
                </NavLink>
            ) : (
                <a
                    {...item.link}
                    className={itemClazzName}
                >
                    {item.children}{item.items && <span className="scl-h-text--tiny">&nbsp;({item.items.length})</span>}
                    {!!item.beta && <ComingSoon />}
                </a> )
    );
};

const menuItems = (
    newMenuItems: MenuItem[],
    depth: number,
    orientation: MenuOrientation,
    collapsable?: boolean,
): JSX.Element | undefined => {
    if (newMenuItems) {
        const processedItems = newMenuItems.map((item, index) => (
            <li
                className="scl-o-menu__item"
                key={`menu-item-${depth}-${index}${(item.children ? item.children.toString() : "")}`}
            >
                {
                    /* If this is a horizontal menu then add Dropdown with a vertical menu */
                    (item.items && depth === 0 &&
                        (orientation === MenuOrientation.HORIZONTAL ||
                            (orientation === MenuOrientation.VERTICAL && collapsable)
                        ) ? (orientation === MenuOrientation.HORIZONTAL) ?
                            (<Dropdown
                                variant={DropdownVariant.MENU}
                                trigger={getLinkContent(item)}
                                content={(
                                    <Menu
                                        orientation={MenuOrientation.VERTICAL}
                                        items={item.items}
                                    />
                                )}
                            />) : (
                                <ToggleMenuItems
                                    trigger={getLinkContent(item, true)}
                                    content={getMoreMenuItems(item, index, orientation)}
                                />
                            )
                        :
                        (
                            <React.Fragment>
                                {getLinkContent(item, depth === 0 && !!item.items && !collapsable)}
                                {getMoreMenuItems(item, index, orientation)}
                            </React.Fragment>
                        ))
                }
            </li>),
        );

        const processedItemsWrapper = (
            <ul className="scl-o-menu__list">
                {processedItems}
            </ul>);

        return processedItemsWrapper;
    } else {
        return undefined;
    }
};

const getMoreMenuItems = (item: MenuItem, index: number, orientation: MenuOrientation): React.ReactNode => (
    item.items ? menuItems(item.items, index, orientation) : undefined
);

/**
 *
 * @param props
 */
const MenuComp: React.FunctionComponent<Props> = (props) => {
    const clazzName = joinClasses(
        props.className || "",
        "scl-o-menu",
        props.orientation === MenuOrientation.VERTICAL ? "scl-o-menu-vertical" : "scl-o-menu-horizontal",
    );

    return (
        <div className={clazzName}>
            { props.title &&
                <div className="scl-o-menu__title">
                    <a {...props.title}>
                        {props.title.children}
                    </a>
                </div>
            }
            {props.items ? menuItems(props.items, 0, props.orientation, props.collapsable) : undefined}
        </div>
    );
};

/**
 *
 */
export const Menu = asThemeConsumer(MenuComp);

export const testUtil = {
    getMoreMenuItems: getMoreMenuItems,
    menuItems: menuItems,
    getLinkContent: getLinkContent,
};
