import React from "react";

import NotFoundImage from "../../../../assets/images/libs/core/404.png";
import ManNotFoundImage from "../../../../assets/images/themes/man/404.svg";
import { Themes } from "../../../../theme/_Types";
import { BaseConsumer } from "../../03-base/Base";
import { Div } from "../../03-base/Div";
import { Header } from "../01-sections/Header";

interface OwnProps {
    title: string;
    description: string;
    customImage?: string;
}

type Props = OwnProps;

export const LostAndFound: React.FC<Props> = (props) => (
    <React.Fragment>
        <div style={{textAlign: "center"}} >
            <Header title={props.title} description={props.description} />
        </div>
        <BaseConsumer>
            {({ getTheme }) =>
                ((getTheme() === Themes.MAN || getTheme() === Themes.CLAUDE) && (
                    <Div
                        theme={{
                            padding: {
                                "": { t: 2 },
                                "sm": { t: 3 },
                            },
                        }}
                    >
                        <img
                            src={getTheme() !== Themes.MAN ? NotFoundImage : ManNotFoundImage}
                            alt="Claude. 404."
                            style={{
                                maxWidth: getTheme() !== Themes.MAN ? "260px" : "64px",
                                alignSelf: "center",
                                margin: "0 auto",
                                display: "block",
                                transform: getTheme() !== Themes.MAN ? "translateX(15px)" : "translateX(0)",
                            }}
                        />
                    </Div>)
                )}
        </BaseConsumer>
    </React.Fragment>
);
