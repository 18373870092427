import * as React from "react";
import { joinClasses } from "../../../utils/Filters";
import { asThemeConsumer } from "../../../theme/Theme";
import { ThemeConsumerProps } from "../../../theme/_Types";

/**
 *
 */
export type NavbarProps = ThemeConsumerProps;

/**
 *
 * @param props
 */
export const NavbarComp: React.FunctionComponent<NavbarProps> =
({ children, className }) => {
    const clazzName = joinClasses(
        "scl-o-navbar",
        className,
    );

    return (
        <div className={clazzName}>
            <div className="scl-o-navbar__content">
                {children}
            </div>
        </div>
    );
};

/**
 *
 */
export const Navbar = asThemeConsumer(NavbarComp);
