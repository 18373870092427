export type StateInit<T extends Readonly<T>> = () => T;

/**
 *
 */
export class State<T extends Readonly<T>> {


    /**
     *
     * @param state
     * @param init
     */
    public constructor(
        private readonly state: T,
        private readonly init: StateInit<T>,
    ) { }

    /**
     *
     * @param init
     */
    public static create<T>(init: StateInit<T>): State<T> {
        return new State<T>(init(), init);
    }


    /**
     *
     */
    public map<U>(fn: (state: T) => U): U {
        return fn(this.state);
    }

    /**
     *
     * @param fn
     */
    public consume(fn: (state: T) => void): void {
        fn(this.state);
    }

    /**
     *
     * @param fn
     */
    public update(fn: (state: T) => T): State<T> {
        const next = fn(this.state);
        return next === this.state
            ? this
            : new State(next, this.init);
    }

    /**
     *
     * @param key
     */
    public prop<K extends keyof T>(key: K): T[K] {
        return this.state[key];
    }

    /**
     *
     * @param key
     * @param fn
     */
    public mapProp<U, K extends keyof T>(key: K, fn: (prop: T[K]) => U): U {
        return fn(this.prop(key));
    }

    /**
     *
     * @param key
     * @param fn
     */
    public consumeProp<K extends keyof T>(key: K, fn: (prop: T[K]) => void): void {
        fn(this.prop(key));
    }

    /**
     *
     * @param key
     * @param val
     */
    public setProp<K extends keyof T>(key: K, val: T[K]): State<T> {
        const update: Partial<T> = {};
        update[key] = val;
        return this.cloneWith(update);
    }

    /**
     *
     * @param ns
     */
    public updateWith(ns: Partial<T>): State<T> {
        return this.cloneWith(ns);
    }

    /**
     *
     */
    public clear(exclude?: Partial<Record<keyof T, boolean>>): State<T> {
        if (!exclude) {
            return State.create(this.init);
        }

        const cleared = this.init();
        const state = this.state;

        return this.cloneWith(Object
            .keys(exclude)
            .reduce(
                (prev, key) => {
                    // eslint-disable-next-line
                    prev[key] = state[key];
                    return prev;
                },
                cleared,
            ));
    }

    /**
     *
     * @param ns the new (partial) state
     */
    protected cloneWith(ns: Partial<T>): State<T> {
        // if no changing keys, return self
        if (!ns) {
            return this;
        }

        // if keys match current state identity, return self
        const index = Object
            .keys(ns)
            .findIndex(
                ((k: string) => ns[k] !== this.state[k]) // eslint-disable-next-line
                    .bind(this));

        //
        return (index < 0)
            ? this
            : new State<T>(
                {
                    ...this.state,
                    ...ns,
                },
                this.init);
    }
}
