import * as React from "react";

import { asThemeConsumer } from "../../../../theme/Theme";
import { joinClasses } from "../../../../utils/Filters";
import { FlexColumn, FlexColumnProps } from "./Column";

/**
 *
 */
interface Props
    extends FlexColumnProps {
    isModal?: boolean;
    isCollapsed?: boolean;
}

/**
 *
 * @param props
 */
const FlexSidebarComp: React.FunctionComponent<Props> = (props) => {
    const modalClass =
        !!props.isModal
            ? joinClasses("scl-b-app__sidebar--modal", "scl-h-animation--sidebar-modal")
            : undefined;
    const clazzName = joinClasses(
        props.className,
        "scl-b-app__sidebar",
        props.isCollapsed ? "scl-b-app__sidebar-collapsed" : "scl-b-app__sidebar-expanded",
        modalClass,
    );

    const processed = {
        ...props,
        className: clazzName,
        isFixed: true,
        isPage: true,
    };

    return (
        <FlexColumn {...processed} />
    );
};

/**
 *
 */
export const FlexSidebar = asThemeConsumer(FlexSidebarComp);
