import * as React from "react";
import { Div } from "../../03-base/Div";
import * as I18n from "../../../../utils/translations/I18n";

/**
 *
 */
export const ComingSoon = () => (
    <Div
        style={{
            display: "inline-block",
            marginLeft: "5px",
            marginRight: "-5px",
            transform: "translateY(-7px) scale(.75)",
            transformOrigin: "left center",
            position: "relative",
            pointerEvents: "none",
        }}
    >
        <Div
            className="scl-a-badge scl-a-badge--brand-primary"
        >
            {
                I18n.getText("mary.09-views.00-blocks.comingsoon.title",
                    "Coming soon")
            }
        </Div>
    </Div>
);
