import { Optional } from "../Optional";
import {
    MaryActionTypeKeys,
    MaryInitAction,
    MaryUnlinkAction,
    MaryUpdateAction,
} from "./ActionTypes";
import {
    MaryState,
    maryStateInit,
} from "./MaryProvider";
import { State } from "./State";
import { Reducers } from "./StateReducers";

/**
 *
 * @param red
 */
export const withMaryReducers =
    <A extends string, S extends { mary?: MaryState }>
    (red: Reducers<MaryActionTypeKeys | A, State<S>>): Reducers<MaryActionTypeKeys | A, State<S>> => (
        red
            .register(
                MaryActionTypeKeys.MARY_INIT,
                maryInitReducer,
            )
            .register(
                MaryActionTypeKeys.MARY_UPDATE,
                maryUpdateReducer,
            )
            .register(
                MaryActionTypeKeys.MARY_UNLINK,
                maryUnlinkReducer,
            )
    );

/**
 *
 */
// export const maryReducer = withMaryReducers(Reducers
//     .create<MaryActionTypeKeys, MaryState>());

/**
 *
 * @param s
 * @param a
 */
const maryInitReducer:
<S extends { mary?: MaryState }>
(s: State<S>, a: MaryInitAction) => State<S> =
    (s, a) => (
        maryUpdateReducer(
            s.setProp("mary", State.create(maryStateInit)),
            {
                ...a,
                type: MaryActionTypeKeys.MARY_UPDATE,
            },
        )
    );

/**
 *
 */
const maryUpdateReducer:
<S extends { mary?: MaryState }>
(s: State<S>, a: MaryUpdateAction) => State<S> =
    (s, a) => (
        s.setProp(
            "mary",
            Optional.ofOpt<MaryState>(s.prop("mary"))
                .map(m => (m
                    .setProp(
                        "breakpoints",
                        m.prop("breakpoints").updateWith({ ...a.breakpoint }),
                    )
                    .setProp(
                        "modal",
                        m.prop("modal").updateWith({ ...a.modalWrapper }),
                    )
                    .setProp(
                        "flex",
                        m.prop("flex").updateWith({ ...a.flexWrapper }),
                    )
                ))
                .getOpt(),
        )
    );

/**
 *
 * @param s
 * @param a
 */
const maryUnlinkReducer:
<S extends { mary?: MaryState }>
(s: State<S>, a: MaryUnlinkAction) => State<S> =
    (s, _a) => (
        s.setProp("mary", undefined)
    );
