import React from "react";
import { Page } from "../01-sections/Page";
import { LoadingIndicator, LoadingIndications } from "../../05-atoms/LoadingIndicator";
import { RTE } from "../../07-organisms/RTE";
import { Div } from "../../03-base/Div";
import { Container } from "../../03-base/Container";
import * as I18n from "../../../../utils/translations/I18n";

interface OwnProps {
    // nuttin yet.
}

type Props = OwnProps;

export const Loading: React.FunctionComponent<Props> = (_props) => (
    <Page>
        <Container
            theme={{
                padding: {
                    "": { y: 3 },
                    "sm": { y: 4 },
                },
            }}
        >
            <LoadingIndicator type={LoadingIndications.DEFAULT} />
            <Div
                theme={{
                    padding: {
                        "": { b: 3 },
                        "sm": { b: 4 },
                    },
                }}
                className="scl-h-text-align--center"
            >
                <RTE content={I18n.getText("mary.09-views.02-pages.loading.description",
                    "Retrieving content..")}/>
            </Div>
        </Container>
    </Page>
);
