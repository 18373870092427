import {I18n} from "aws-amplify";

import { I18nAmplifyAuthTranslationsNL, I18nMaryTranslationsNL } from "./nl/I18n";

export enum I18nLanguage {
    ENGLISH = "en",
    DUTCH = "nl",
}

export const I18nMarylocales = [I18nLanguage.ENGLISH, I18nLanguage.DUTCH];

export const I18nMaryTranslations = {
    nl: {
        ...I18nAmplifyAuthTranslationsNL,
        ...I18nMaryTranslationsNL,
    },
};

export interface I18nTextProps {
    key: string | I18nTextProps;
    dflt?: string;
    vars?: {[key: string]: string};
}

export const getText = (key: string | I18nTextProps,
                        dflt?: string, vars?: {[key: string]: string}): string | undefined => {
    const props = {
        key: typeof key === "string" ? key : key.key as string,
        dflt: typeof key === "string" ? dflt : key.dflt,
        vars: typeof key === "string" ? vars : key.vars,
    };

    let value: string | undefined = I18n.get(props.key, props.dflt) as string;
    if (props.vars && value) {
        Object.keys(props.vars).forEach((k) => {
            if (value){
                value = value.replace(`{{${k}}}`, props.vars ? props.vars[k] : "");
            }
        });
    }
    return value;
};

export const getReactNode = (key: string, dflt?: string | React.ReactNode): React.ReactNode =>
    I18n.get(key, dflt) as React.ReactNode;
