import * as React from "react";

import { ThemeConsumerProps } from "../../../theme/_Types";
import { joinClasses } from "../../../utils/Filters";

/**
 * misuse is thrown when FlexWrapper is consumed without a valid provider being
 * present.
 */
const misuse = new Error("misuse error: no FlexWrapperProvider in ancestors");

/**
 * FlexWrapperFocus is an enum specifying the varius focus targets supported by
 * the FlexWrapper component. This is used to determine focus/render priority on
 * smaller devices, such as mobile.
 */
export enum FlexWrapperFocus {
    /**
     * CONTENT indicates render prioritization for the application's content
     * components.
     */
    CONTENT = "content",
    /**
     * SIDEBAR indicates render prioritization for the application's Sidebar
     * component.
     */
    SIDEBAR = "sidebar",

    /**
     * SIDEBAR_INACTIVE indicates that the SIDEBAR is there but should be hidden
     * component.
     */
    SIDEBAR_INACTIVE = "sidebar-inactive",
}

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    /**
     * defaultFocus indicates the initial focus of the flex layout. It is
     * used on the initial render, until the first call to setFocus has been
     * made, or when setFocus resets to undefined.
     */
    defaultFocus: FlexWrapperFocus;
    asWidget?: boolean;
}

/**
 *
 */
interface State {
    /**
     * activeFocus contains the current render priority of the FlexWrapper.
     */
    activeFocus: FlexWrapperFocus;
}

/**
 * FlexWrapperBinding are properties used by consuming components that wish to
 * interact with the state of the Flex layout.
 */
export interface FlexWrapperBinding {
    /**
     * setFocus allows the consumer to update the current focus priority.
     */
    setFocus: (to: FlexWrapperFocus) => void;
    /**
     * getFocus allows the consumer to query the current focus priority.
     */
    getFocus: () => FlexWrapperFocus;
}

/**
 * FlexWrapperBindingInit
 */
export const flexWrapperBindingInit:
() => FlexWrapperBinding =
    () => ({
        setFocus: (_to) => {
            throw misuse;
        },
        getFocus: () => {
            throw misuse;
        },
    });

/**
 *
 */
const FlexWrapperContext = React.createContext<FlexWrapperBinding>(flexWrapperBindingInit());

/**
 * FlexWrapperProvider is the Provider component of the Flex layout component.
 */
export class FlexWrapperProvider
    extends React.Component<Props, State> {

    /**
     *
     * @param props
     */
    public constructor(props: Props) {
        super(props);

        this.state = {
            activeFocus: props.defaultFocus,
        };

        this.setFocus = this.setFocus.bind(this);
        this.getFocus = this.getFocus.bind(this);
    }

    /**
     *
     */
    public render(): JSX.Element {
        const className = joinClasses(
            this.props.className,
            "scl-b-app",
            this.props.asWidget ? "scl-b-app__widget" : undefined,
            FlexWrapperFocus.SIDEBAR === this.getFocus() ? "scl-b-app-sidebar--active" : undefined,
            FlexWrapperFocus.SIDEBAR_INACTIVE === this.getFocus() ? "scl-b-app-sidebar--inactive" : undefined,
        );

        const setFocus = this.setFocus;
        const getFocus = this.getFocus;

        return (
            <FlexWrapperContext.Provider
                value={{
                    setFocus: setFocus,
                    getFocus: getFocus,
                }}
            >
                <div className={className}>
                    {this.props.children}
                </div>
            </FlexWrapperContext.Provider>
        );
    }

    /**
     * setFocus implements the setFocus method for the FlexWrapperBinding
     * provided to the Consumers.
     *
     * @param to the new focus priority.
     */
    private setFocus(to: FlexWrapperFocus) {
        this.setState({
            activeFocus: to || this.props.defaultFocus,
        });
    }

    /**
     * getFocus implements the getFocus method for the FlexWrapperBinding
     * provided to the Consumers.
     *
     * @return the currently active focus priority.
     */
    private getFocus(): FlexWrapperFocus {
        return this.state.activeFocus;
    }
}

/**
 * FlexWrapperConsumer is the Consumer component of the Flex layout component.
 */
export const FlexWrapperConsumer = FlexWrapperContext.Consumer;
