/* eslint-disable @typescript-eslint/member-ordering */
import { Action } from "redux";

/**
 * Reducer is a type alias for a Redux Reducer function, taking state of type
 * State, and Action of type A, to produce a new State.
 */
export type Reducer<State, A extends Action> = (s: State, a: A) => State;

/**
 * Reducers implements a pattern of subscribing to a Redux event, and selecting
 * the subscriber to modify state based on the event being sent to Redux.
 *
 * @param A type parameter identifying the enum or union of valid `type`
 * values used to identify redux events.
 * @param State type parameter identifying the root type of the Redux state
 * for which the Reducers will be used.
 */
export class Reducers<A extends string, State> {

    /**
     *
     */
    public static create<A extends string, State>(): Reducers<A, State> {
        return new Reducers<A, State>({} as Record<A, Reducer<State, Action<A>>>);
    }

    /**
     *
     * @param reducers
     */
    private static chainReducers<S, A extends Action>(...reducers: Array<Reducer<S, A>>): Reducer<S, A> {
        return (s, a) => {
            let state = s;
            for (const red of reducers) {
                state = red(state, a);
            }
            return state;
        };
    }

    /**
     *
     * @param actions
     * @param map
     */
    public constructor(
        private readonly map: Record<A, Reducer<State, Action<A>>>,
    ) { }

    /**
     *
     * @param k the key identifying the Action type to subscribe to.
     * @param red a list of one of more reducers to subscribe to the action.
     * @param AType the action's type key (string).
     */
    public register<AType extends A>(k: AType, ...red: Array<Reducer<State, Action<AType>>>): Reducers<A, State> {
        if (!!this.map[k]) {
            throw new Error(`duplicate reducer for key ${k}`);
        }

        if (!red || red.length === 0) {
            throw new Error(`no reducer provided for ${k}`);
        }

        const reducer = (red.length === 1)
            ? red[0]
            : Reducers.chainReducers(...red);

        return new Reducers({
            ...this.map,
            [k]: reducer,
        }) as Reducers<A, State>;
    }

    /**
     *
     * @param s
     * @param a
     */
    public reduce(s: State, a: Action<A>): State {

        if (a.type.startsWith("@@INIT") ||
            a.type.startsWith("@@redux/")) {
            return s;
        }

        const handler = this.map[a.type];
        if (!handler) {
            /* tslint:disable */
            console.error(`[MARY-REDUX] no handler found for ${a.type}`);
            /* tslint:enable */
            return s;
        }

        return handler(s, a);
    }
}
