import React from "react";

interface Props {
    info?: string;
    name: string;
    role?: string;
    company?: string;
    children?: React.ReactNode;
}
export const UserInfo: React.FunctionComponent<Props> = props => (
    <div className="scl-m-userinfo">
        {props.children ? props.children : (<>
            <span className="scl-m-userinfo__name">{props.name + " "}</span>
            {(props.role || props.company) && (
                <span className="scl-m-userinfo__info">
                    {props.role}&nbsp;
                    {props.company && (
                        <span
                            className="scl-m-userinfo__info"
                            style={{ fontSize: "12px", display: "inline-block"}}
                        >
                            {props.company}
                        </span>
                    )}
                </span>
            )}
        </>)}
    </div>
);
