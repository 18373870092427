import * as React from "react";

import { ThemeConsumerProps } from "../../../../theme/_Types";
import { asThemeConsumer } from "../../../../theme/Theme";
import { joinClasses } from "../../../../utils/Filters";

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    isScrollable?: boolean;
    isFixed?: boolean;
    verticalAlignContent?: boolean;
    scrollRef?: React.RefObject<HTMLDivElement>;
    isCollapsed?: boolean;
}

/**
 *
 * @param props
 */
const FlexRowComp: React.FunctionComponent<Props> = (props) => {
    const appRowClassName = props.isCollapsed ? "scl-b-app__row-collapsed" : "scl-b-app__row";
    const scrollableClassName =
        !!props.isScrollable
            ? "scl-b-app__row--scrollable"
            : undefined;
    const isFixedClassName =
        !!props.isFixed
            ? "scl-b-app__row--fixed"
            : undefined;
    const verticalAlignContentClassName =
        !!props.verticalAlignContent
            ? "scl-b-app__row--v-center"
            : undefined;

    const className = joinClasses(
        props.className,
        "scl-h-background",
        appRowClassName,
        scrollableClassName,
        isFixedClassName,
        verticalAlignContentClassName,
    );

    return (
        <div
            className={className}
            ref={props.scrollRef}
            style={props.style}
        >
            {props.children}
        </div>
    );
};

/**
 *
 */
export const FlexRow = asThemeConsumer(FlexRowComp);
