import * as React from "react";
import { connect } from "react-redux";
import { Action, Dispatch } from "redux";

import {
    FlexWrapperBinding,
    FlexWrapperConsumer,
    FlexWrapperFocus,
    FlexWrapperProvider,
    flexWrapperBindingInit,
} from "../../components/core/03-base/FlexWrapper";
import {
    ModalWrapperBinding,
    ModalWrapperConsumer,
    ModalWrapperProvider,
    modalWrapperBindingInit,
} from "../../components/core/06-molecules/ModalWrapper";
import {
    BreakpointBinding,
    BreakpointConsumer,
    BreakpointProvider,
    breakpointBindingInit,
} from "../Breakpoints";
import { Opt, ensure } from "../Optional";
import { MaryActionTypeKeys, maryInit, maryUnlink, maryUpdate } from "./ActionTypes";
import { State, StateInit } from "./State";

/**
 *
 */
interface Props {
    flexDefaultFocus?: FlexWrapperFocus;
    asWidget?: boolean;
}

/**
 *
 */
export const MaryProvider: React.FunctionComponent<Props> = props => (
    <BreakpointProvider>
        <ModalWrapperProvider>
            <FlexWrapperProvider
                defaultFocus={props.flexDefaultFocus || FlexWrapperFocus.SIDEBAR}
                asWidget={props.asWidget}
            >
                <BreakpointConsumer>
                    {bc => (<ModalWrapperConsumer>
                        {mwc => (<FlexWrapperConsumer>
                            {fw => (<MaryInitializer
                                breakpoint={bc}
                                modalWrapper={mwc}
                                flexWrapper={fw}
                            >
                                {props.children}
                            </MaryInitializer>)}
                        </FlexWrapperConsumer>)}
                    </ModalWrapperConsumer>)
                    }
                </BreakpointConsumer>
            </FlexWrapperProvider>
        </ModalWrapperProvider>
    </BreakpointProvider>
);

//
type TMaryState = Readonly<{
    breakpoints: State<BreakpointBinding>;
    modal: State<ModalWrapperBinding>;
    flex: State<FlexWrapperBinding>;
}>;

/**
 *
 */
export type MaryState = State<TMaryState>;

/**
 *
 */
export const maryStateInit: StateInit<TMaryState> =
    () => ({
        breakpoints: State.create(breakpointBindingInit),
        flex: State.create(flexWrapperBindingInit),
        modal: State.create(modalWrapperBindingInit),
    });

/**
 *
 */
export interface MaryBinding {
    breakpoint?: BreakpointBinding;
    flexWrapper?: FlexWrapperBinding;
    modalWrapper?: ModalWrapperBinding;
}

/**
 *
 */
interface MaryInitializerPropsInterface
    extends MaryBinding {
    linked?: boolean;
    dispatch?: Dispatch<Action<MaryActionTypeKeys>>;
}
type MaryInitializerProps = React.PropsWithChildren<MaryInitializerPropsInterface>;


/**
 *
 */
class MaryInitializerComp
    extends React.Component<MaryInitializerProps, {}> {


    /**
     *
     * @param props
     */
    public constructor(props: MaryInitializerProps) {
        super(props);
    }

    /**
     *
     * @param s
     * @param ownProps
     */
    public static mapStateToProps
    <S extends { mary: MaryState }>(s: State<S>, _ownProps: MaryInitializerProps):
    Partial<MaryInitializerProps> {
        return {
            linked: !!s.prop("mary"),
        };
    }

    /**
     *
     * @param dispatch
     */
    public static mapDispatchToProps(dispatch: Dispatch): Partial<MaryInitializerProps> {
        return {
            dispatch: dispatch,
        };
    }

    /**
     * componentDidMount will trigger the redux action to link the mary
     * providers into the redux store.
     */
    public componentDidMount() {
        ensure(this.props.dispatch)(maryInit({ ...this.props }));
    }

    /**
     * componentDidUpdate is responsible for keeping the redux state in sync
     * with the state from the providers.
     */
    public componentDidUpdate() {
        ensure(this.props.dispatch)(maryUpdate({ ...this.props }));
    }

    /**
     * componentWillUnmount will trigger the redux action to un-link the Mary
     * providers.
     */
    public componentWillUnmount() {
        ensure(this.props.dispatch)(maryUnlink());
    }

    /**
     *
     */
    public render() {
        return !this.props.linked
            ? null
            : this.props.children;
    }
}

/**
 *
 */
const MaryInitializer = connect(
    MaryInitializerComp.mapStateToProps,
    MaryInitializerComp.mapDispatchToProps,
)(MaryInitializerComp);

/**
 *
 * @param s
 */
export const maryOf:
<S extends { mary?: MaryState }>
(s: State<S>) => MaryState =
    s => {
        const m: Opt<MaryState> = s.prop("mary");
        return ensure(m);
    };
