/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react/display-name */
import * as React from "react";

import { joinClasses } from "../utils/Filters";
import { Opt } from "../utils/Optional";
import {
    SpacingValues,
    ThemeAnimations,
    ThemeConsumerProps,
    ThemePalette,
    ThemePaletteShade,
    ThemePaletteState,
    ThemePropsMapper,
    ThemeProviderProps,
    ThemeShadowSizes,
    ThemeSpacingsProps,
} from "./_Types";

/**
 *
 * @param pl
 */
export const createPalleteClassName = (pl?: ThemePalette): Opt<string> => {
    // Color classes

    if (!pl) {
        return undefined;
    }

    return `scl-h-color--${pl}`;
};

/**
 *
 * @param pl
 */
export const createPalleteShadeClassName = (pl?: ThemePaletteShade): Opt<string> => {
    // Color classes

    if (!pl) {
        return undefined;
    }

    return `scl-h-shade--${pl}`;
};

/**
 *
 * @param pl
 */
export const createPalleteStateClassName = (pl?: ThemePaletteState): Opt<string> => {
    if (!pl) {
        return undefined;
    }

    // State classes
    return `scl-h-state--${pl}`;
};

/**
 *
 * @param prefix
 * @param spacing
 */
export const createSpacingClassName = (prefix: string, spacing?: ThemeSpacingsProps): Opt<string> => {
    if (!spacing) {
        return undefined;
    }

    if (typeof spacing === "number") {
        return `scl-h-spacing-${prefix}-${spacing}`;
    }

    return Object
        .keys(spacing)
        .reduce(
            (value, bp) => (
                Object
                    .keys(spacing[bp])
                    .reduce(
                        (v, pos) => {
                            const bpKey = bp as keyof Partial<Readonly<{
                                "": SpacingValues;
                                xs: SpacingValues;
                                sm: SpacingValues;
                                md: SpacingValues;
                                lg: SpacingValues;
                                xl: SpacingValues;
                            }>>;
                            // spacing[bp][pos]
                            const spacingBp = spacing[bpKey];
                            const posKey = pos as keyof Partial<Readonly<{
                                l: number;
                                r: number;
                                t: number;
                                b: number;
                                x: number;
                                y: number;
                            }>>;
                            const posValue = spacingBp?.[posKey] ?? "";
                            return joinClasses(v, `scl-h-spacing-${prefix}${pos}-${bp}${posValue}`);
                        }
                        ,
                        value,
                    )
            ),
            "",
        );
};

/**
 *
 * @param an
 */
const createAnimationClassName = (an?: ThemeAnimations): string | undefined => {
    if (!an) {
        return undefined;
    }

    // Animation classes
    return `scl-h-animation--${an}`;
};

/**
 *
 * @param sh
 */
const createShadowClassName = (sh?: ThemeShadowSizes): string | undefined => {
    if (!sh) {
        return undefined;
    }

    // Shadow classes
    return `scl-h-shadow--${sh}`;
};

/**
 *
 * @param param
 */
export const mapThemePropsToClassNames:
(props: ThemeProviderProps) => Opt<string> =
    ({ margin, padding, palette, paletteShade, paletteState, animation, shadow }) => (
        joinClasses(
            createSpacingClassName("m", margin),
            createSpacingClassName("p", padding),
            createPalleteClassName(palette),
            createPalleteShadeClassName(paletteShade),
            createPalleteStateClassName(paletteState),
            createAnimationClassName(animation),
            createShadowClassName(shadow),
        )
    );

/**
 *
 */
type ComponentClazz<Props> =
    React.FC<Props> |
    React.ComponentClass<Props>;

/**
 *
 * @param Clazz
 * @param mttc mapStateToClassname mapping function for a component's specific
 * theme properties.
 */
export const asThemeConsumer:
<T extends ThemeConsumerProps, U extends ThemeProviderProps = ThemeProviderProps>(
    Clazz: ComponentClazz<T>,
    mttc?: ThemePropsMapper<U>,
) => React.FC<T & { theme?: Partial<Readonly<U>> }> =
    (Clazz, mttc) => (props) => {
        const theme = props.theme || {};
        const className = props.className;

        const themeClassName = mapThemePropsToClassNames(theme);
        const specificClassName = !!mttc ? mttc(theme) : undefined;

        const processed = {
            ...props,
            className: joinClasses(className, themeClassName, specificClassName),
        };


        return (
            <Clazz
                {...processed}
            />
        );
    };
