import * as React from "react";

import { joinClasses } from "../../..//utils/Filters";
import { ThemeProps, ThemeVariants } from "../../../theme/_Types";
import { BaseConsumer } from "../03-base/Base";
import { FlexRow } from "../03-base/Flex/Row";
import { Logo } from "../03-base/Logo";

/**
 *
 */
interface Props
    extends ThemeProps {
    className?: string;
    variant?: ThemeVariants;
}

/**
 *
 * @param props
 */
export const FillUp: React.FunctionComponent<Props> = (props) => {
    const className = joinClasses(
        "scl-h-filler",
        props.className,
    );

    return (
        <FlexRow>
            <BaseConsumer>{({ getTheme }) =>
                (
                    <div className={className}>
                        {props.children}
                        <div className="scl-h-filler__logo">
                            <Logo variant={props.variant ?? ThemeVariants.SHADED} theme={getTheme()} />
                        </div>
                    </div>
                )
            }
            </BaseConsumer>
        </FlexRow>
    );
};
