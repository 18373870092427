import * as React from "react";
import { ThemeBreakpoints, ThemePalette, ThemePaletteState,
    ThemeShadowSizes, ThemeSize } from "../../../../theme/_Types";
import { Container } from "../../03-base/Container";
import { Div } from "../../03-base/Div";
import { FlexColumn } from "../../03-base/Flex/Column";
import { FlexRow } from "../../03-base/Flex/Row";
import { FillUp } from "../../04-helpers/FillUp";
import { Button } from "../../05-atoms/Button";
import { Alignment, ButtonsWrapper, Orientation } from "../../06-molecules/ButtonsWrapper";
import { Modal } from "../../06-molecules/Modal";
import { Navbar } from "../../07-organisms/Navbar";
import { NavbarStack, NavbarStackContent } from "../../07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../07-organisms/Navbar/Title";
import * as I18n from "../../../../utils/translations/I18n";
import { mailToData, refreshMary } from "../../../../utils/General";

/**
 *
 */
interface Props {
    info?: string;
}

const errorModal = (info?: string) => (
    <Modal
        modalID={"error"}
        theme={{ size: ThemeSize.SMALL }}
        inline
    >
        <FlexColumn
            theme={{
                palette: ThemePalette.CONTRAST_PRIMARY,
                shadow: ThemeShadowSizes.HUGE,
            }}
        >
            <FlexRow isFixed>
                <Navbar
                    theme={{
                        paletteState: ThemePaletteState.DANGER,
                    }}
                >
                    <NavbarStack>
                        <NavbarStackContent>
                            <NavbarTitle>
                                {I18n.getText(
                                    "mary.09-views.02-pages.error.title",
                                    "Oops, something went wrong!")}
                            </NavbarTitle>
                        </NavbarStackContent>
                    </NavbarStack>
                </Navbar>
            </FlexRow>
            <FlexRow
                theme={{
                    palette: ThemePalette.CONTRAST_PRIMARY,
                }}
            >
                <FlexColumn>
                    <FlexRow isScrollable>
                        <FillUp>
                            <Container
                                theme={{
                                    padding: {
                                        "": { y: 3 },
                                        "md": { y: 4 },
                                    },
                                }}
                            >
                                <div className="scl-b-h5">{
                                    I18n.getText(
                                        "mary.09-views.02-pages.error.content-title",
                                        "There has been a technical error")
                                }</div>
                                <br />
                                <p>{
                                    I18n.getText(
                                        "mary.09-views.02-pages.error.content-description",
                                        "Send the following information to team Claude of VWPFS.")
                                }
                                </p>
                                <br />
                                <Div
                                    style={{ maxHeight: "200px", overflowY: "scroll", wordWrap: "break-word" }}
                                    theme={{
                                        palette: ThemePalette.CONTRAST_SECONDARY,
                                        padding: {
                                            "": { y: 2, x: 2 },
                                            "md": { y: 3, x: 3 },
                                        }                                    }}>
                                    {info ||
                                        I18n.getText(
                                            "mary.09-views.02-pages.error.content-fallback-message",
                                            // eslint-disable-next-line max-len
                                            "Claude is not able to collect technical data, this seems to be a serious problem. :(")
                                    }
                                </Div>
                            </Container>
                        </FillUp>
                    </FlexRow>
                </FlexColumn>
            </FlexRow>
            <FlexRow isFixed>
                <FlexColumn
                    theme={{
                        palette: ThemePalette.CONTRAST_SECONDARY,
                    }}
                >
                    <Container
                        theme={{
                            padding: {
                                "": { y: 3 },
                            },
                        }}
                    >
                        <ButtonsWrapper
                            defaultOrientation={Orientation.HORIZONTAL}
                            orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL}}
                            alignment={Alignment.RIGHT}>
                            <Div style={{display: "flex"}}>
                                <Button
                                    theme={{
                                        palette: ThemePalette.CONTRAST_SECONDARY,
                                    }}
                                    link={{ onClick: refreshMary }}>
                                    {I18n.getText(
                                        "mary.09-views.refresh-btn",
                                        "Refresh the page")}
                                </Button>
                            </Div>
                            <Div style={{display: "flex"}}>
                                <Button
                                    theme={{
                                        palette: ThemePalette.BRAND_ACCENT,
                                    }}
                                    link={{ href: mailToData(info || "no data collected.") }}
                                >
                                    {I18n.getText(
                                        "mary.09-views.02-pages.error.send-btn",
                                        "Send data")}
                                </Button>
                            </Div>
                        </ButtonsWrapper>
                    </Container>
                </FlexColumn>
            </FlexRow>
        </FlexColumn>
    </Modal>
);

/**
 *
 * @param props
 */
export const ErrorView: React.FunctionComponent<Props> = (props) => (
    <FlexColumn
        isPage
        hasScrollable
        theme={{
            palette: ThemePalette.CONTRAST_SECONDARY,
        }}
    >
        <FlexRow isScrollable>
            <FillUp
                theme={{
                    palette: ThemePalette.CONTRAST_SECONDARY,
                }}
            >
                {errorModal(props.info)}
            </FillUp>
        </FlexRow>
    </FlexColumn>
);
