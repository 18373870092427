/* eslint-disable max-len */
import { Action } from "redux";
import { ActionTypeKeys } from "../ActionTypes";
import { ReducerFn } from "../ReduxState";

type UpdateSelectedCustomerAction = Readonly<
Action<ActionTypeKeys.UPDATE_CURRENT_CUSTOMER> & {
    selectedCustomer?: string;
}
>;

export const updateSelectedCustomer:
(selectedCustomer?: string) => UpdateSelectedCustomerAction =
    (selectedCustomer) => ({
        type: ActionTypeKeys.UPDATE_CURRENT_CUSTOMER,
        selectedCustomer: selectedCustomer,
    });

export const updateSelectedCustomerReducer:
ReducerFn<UpdateSelectedCustomerAction> =
    (s, a) => {
        localStorage.setItem("selectedCustomer", a.selectedCustomer ?? "");
        return (
            s.setProp("selectedCustomer", a.selectedCustomer ?? "")
        );
    };

export type ClearSelectedCustomerAction = Readonly<
Action<ActionTypeKeys.CLEAR_CURRENT_CUSTOMER>
>;

/**
 *
 *
 */
export const clearSelectedCustomer:
() => ClearSelectedCustomerAction =
    () => ({
        type: ActionTypeKeys.CLEAR_CURRENT_CUSTOMER,
    });

/**
 *
 */
export const clearSelectedCustomerReducer:
ReducerFn<ClearSelectedCustomerAction> =
    (s, _a) => (
        s.setProp("selectedCustomer", undefined)
    );
