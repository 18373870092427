import * as React from "react";

import { ThemeConsumerProps, Themes, ThemeVariants } from "../../../theme/_Types";

import ClaudeBrandLogo from "../../../assets/images/themes/claude/logo-brand.svg";
import ClaudeContrastLogo from "../../../assets/images/themes/claude/logo-contrast.svg";
import ClaudeShadedLogo from "../../../assets/images/themes/claude/logo-shaded.svg";

import VWPFSBrandLogo from "../../../assets/images/themes/vwpfs/logo-brand.svg";
import VWPFSContrastLogo from "../../../assets/images/themes/vwpfs/logo-contrast.svg";
import VWPFSShadedLogo from "../../../assets/images/themes/vwpfs/logo-shaded.svg";

import VWBrandLogo from "../../../assets/images/themes/vw/logo-brand.svg";
import VWContrastLogo from "../../../assets/images/themes/vw/logo-contrast.svg";
import VWShadedLogo from "../../../assets/images/themes/vw/logo-shaded.svg";

import AudiBrandLogo from "../../../assets/images/themes/audi/logo-brand.svg";
import AudiContrastLogo from "../../../assets/images/themes/audi/logo-contrast.svg";
import AudiShadedLogo from "../../../assets/images/themes/audi/logo-shaded.svg";

import SkodaBrandLogo from "../../../assets/images/themes/skoda/logo-brand.svg";
import SkodaContrastLogo from "../../../assets/images/themes/skoda/logo-contrast.svg";
import SkodaShadedLogo from "../../../assets/images/themes/skoda/logo-shaded.svg";

import SeatBrandLogo from "../../../assets/images/themes/seat/logo-brand.svg";
import SeatContrastLogo from "../../../assets/images/themes/seat/logo-contrast.svg";
import SeatShadedLogo from "../../../assets/images/themes/seat/logo-shaded.svg";

import DutchLeaseBrandLogo from "../../../assets/images/themes/dutchlease/logo-brand.svg";
import DutchLeaseContrastLogo from "../../../assets/images/themes/dutchlease/logo-contrast.svg";
import DutchLeaseShadedLogo from "../../../assets/images/themes/dutchlease/logo-shaded.svg";

import XLLeaseBrandLogo from "../../../assets/images/themes/xllease/logo-brand.svg";
import XLLeaseContrastLogo from "../../../assets/images/themes/xllease/logo-contrast.svg";
import XLLeaseShadedLogo from "../../../assets/images/themes/xllease/logo-shaded.svg";

import ManBrandLogo from "../../../assets/images/themes/man/logo-brand.svg";
import ManContrastLogo from "../../../assets/images/themes/man/logo-contrast.svg";
import ManShadedLogo from "../../../assets/images/themes/man/logo-shaded.svg";

import PolitieBrandLogo from "../../../assets/images/themes/politie/logo-brand.svg";
import PolitieContrastLogo from "../../../assets/images/themes/politie/logo-contrast.svg";
import PolitieShadedLogo from "../../../assets/images/themes/politie/logo-shaded.svg";

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    theme: Themes;
    variant: ThemeVariants;
    img?: string;
}

/**
 *
 * @param props
 */
export const Logo: React.FunctionComponent<Props> = (props) => {
    const getLogo = () => {
        switch (props.theme) {
        case Themes.VWPFS:
            return {
                [ThemeVariants.BRAND]: VWPFSBrandLogo,
                [ThemeVariants.CONTRAST]: VWPFSContrastLogo,
                [ThemeVariants.SHADED]: VWPFSShadedLogo,
            };
        case Themes.VW:
            return {
                [ThemeVariants.BRAND]: VWBrandLogo,
                [ThemeVariants.CONTRAST]: VWContrastLogo,
                [ThemeVariants.SHADED]: VWShadedLogo,
            };
        case Themes.AUDI:
            return {
                [ThemeVariants.BRAND]: AudiBrandLogo,
                [ThemeVariants.CONTRAST]: AudiContrastLogo,
                [ThemeVariants.SHADED]: AudiShadedLogo,
            };
        case Themes.SKODA:
            return {
                [ThemeVariants.BRAND]: SkodaBrandLogo,
                [ThemeVariants.CONTRAST]: SkodaContrastLogo,
                [ThemeVariants.SHADED]: SkodaShadedLogo,
            };
        case Themes.SEAT:
            return {
                [ThemeVariants.BRAND]: SeatBrandLogo,
                [ThemeVariants.CONTRAST]: SeatContrastLogo,
                [ThemeVariants.SHADED]: SeatShadedLogo,
            };
        case Themes.DUTCHLEASE:
            return {
                [ThemeVariants.BRAND]: DutchLeaseBrandLogo,
                [ThemeVariants.CONTRAST]: DutchLeaseContrastLogo,
                [ThemeVariants.SHADED]: DutchLeaseShadedLogo,
            };
        case Themes.XLLEASE:
            return {
                [ThemeVariants.BRAND]: XLLeaseBrandLogo,
                [ThemeVariants.CONTRAST]: XLLeaseContrastLogo,
                [ThemeVariants.SHADED]: XLLeaseShadedLogo,
            };
        case Themes.MAN:
            return {
                [ThemeVariants.BRAND]: ManBrandLogo,
                [ThemeVariants.CONTRAST]: ManContrastLogo,
                [ThemeVariants.SHADED]: ManShadedLogo,
            };
        case Themes.POLITIE:
            return {
                [ThemeVariants.BRAND]: PolitieBrandLogo,
                [ThemeVariants.CONTRAST]: PolitieContrastLogo,
                [ThemeVariants.SHADED]: PolitieShadedLogo,
            };
        default:
            return {
                [ThemeVariants.BRAND]: ClaudeBrandLogo,
                [ThemeVariants.CONTRAST]: ClaudeContrastLogo,
                [ThemeVariants.SHADED]: ClaudeShadedLogo,
            };
        }
    };

    const logos = getLogo();
    return (
        <img src={props.img ? props.img : logos[props.variant]}
            alt={props.theme} style={{ ...props.style }} className={props.className} />
    );
};
