import * as React from "react";
import { Themes, ThemeVariants } from "../../../theme/_Types";
import { Logo } from "./Logo";
import "../../../assets/stylesheets/libs/core/03-base/loader.scss";

interface Props {
    force?: boolean;
}

/**
 *
 * @param props
 */
export const PreLoader: React.FunctionComponent<Props> = (props) => (
    <div className={`scl-preloader__wrapper ${props.force ? "scl-preloader__wrapper-forcedshow" : ""}`}>
        <div className="scl-preloader">
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            <Logo theme={Themes.CLAUDE} variant={ThemeVariants.CONTRAST}/>
        </div>
    </div>
);

