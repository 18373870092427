import { AxiosRequestConfig, AxiosResponse, CancelTokenSource, ResponseType } from "axios";

import { Opt } from "../utils/Optional";
import { DispatchFunc } from "./ActionTypes";
import { ReduxState, RemoteState } from "./ReduxState";
import { KPIPerCustomerRequest, KPIs } from "./KPIs/Types";

export const DEFAULT_PAGE_SIZE = 25;

export enum RemoteErrorType {
    DEFAULT = "DEFAULT",
    VALIDATION = "VALIDATION",
    SYSTEM_ERROR = "SYSTEM_ERROR",
    UNKNOWN_ERROR = "UNKNOWN_ERROR",
    AUTHORIZATION = "AUTHORIZATION_ERROR",
}

export type MethodType = "GET" | "PUT" | "POST" | "DELETE" | "OPTIONS" | "HEAD" | "PATCH";

export interface HeadersType {
    [key: string]: string;
}

export interface RemoteConfig<T extends RemoteScope> {
    scope: T;
    identifier?: string;
    pathMapper: string | ((state: ReduxState, ctx: RemoteContexts[T]) => string);
    responseMapper?: (resp: AxiosResponse<any>, state: ReduxState, ctx: RemoteContexts[T]) => Opt<{}>;
    method?: MethodType;
    bodyMapper?: (state: ReduxState, ctx: RemoteContexts[T]) => {} | undefined;
    source?: CancelTokenSource;
    paramsMapper?: (state: ReduxState, ctx: RemoteContexts[T]) => {};
    resMapper: (resp: AxiosResponse, s: ReduxState, ctx: RemoteContexts[T]) => RemoteState[T];
    onSuccess?: (dispatch: DispatchFunc, state: ReduxState, ctx: RemoteContexts[T]) => void;
    onError?: (dispatch: DispatchFunc, state: ReduxState, ctx: RemoteContexts[T]) => void;
    onInit?: (dispatch: DispatchFunc, state: ReduxState, ctx: RemoteContexts[T]) => void;
    headers?: (state: ReduxState, ctx: RemoteContexts[T]) => HeadersType;
    // headers?: HeadersType;
    dontIncludeAuthHeader?: boolean;
    responseType?: ResponseType;
}

// TODO: Remove if not used
export interface RequestObject {
    totalElements: number;
    totalPages: number;
    number: number;
}

export interface RefObject {
    id: number;
}

export const hasValue = (value?: string | number | RefObject) => !!value || value === 0;

export const hasRequiredFields =
    <T, K extends keyof T>(obj: T, fields: K[]) =>
        fields.every(k => hasValue(obj[k] as unknown as string | number | RefObject));

/**
 *
 */
export type RemoteStatusValues = Readonly<{
    Statuses: Array<{
        Label: string;
        Name: string;
    }>;
}>;

/**
 *
 */
export enum RemoteScope {
    INIT = "INIT",
    CUSTOMERS = "CUSTOMERS",
    KPIS = "KPIS",
    UPDATE_KPIS = "UPDATE_KPIS",
    DELETE_KPI = "DELETE_KPI",
    KPIS_PER_CUSTOMER = "KPIS_PER_CUSTOMER",
    UPDATE_KPIS_PER_CUSTOMER = "UPDATE_KPIS_PER_CUSTOMER",
    DELETE_KPI_PER_CUSTOMER = "DELETE_KPI_PER_CUSTOMER",
}

/**
 *
 */
export interface RemoteContexts {
    [RemoteScope.INIT]: undefined;
    [RemoteScope.CUSTOMERS]: undefined;
    [RemoteScope.KPIS]: undefined;
    [RemoteScope.UPDATE_KPIS]: {
        kpisArray: KPIs;
    };
    [RemoteScope.KPIS_PER_CUSTOMER]: {
        callback?: () => void;
    };
    [RemoteScope.UPDATE_KPIS_PER_CUSTOMER]: {
        kpisArray: KPIPerCustomerRequest[];
        callback?: () => void;
    };
    [RemoteScope.DELETE_KPI]: {
        kpiId: string;
    };
    [RemoteScope.DELETE_KPI_PER_CUSTOMER]: {
        kpiId: string;
        callback?: () => void;
    };
}

/**
 * AxiosInstance.
 */
export const buildRequest =
    async <T extends RemoteScope>(
        state: ReduxState,
        trigger: RemoteConfig<T>,
        ctx: RemoteContexts[T],
    // eslint-disable-next-line @typescript-eslint/require-await
    ): Promise<AxiosRequestConfig> => {
        let headers: HeadersType = {
            // 'defaultHeader': 'test'
        };

        if (trigger.headers) {
            headers = { ...headers, ...trigger.headers };
        }

        if (trigger.dontIncludeAuthHeader !== true) {
            const token = state.prop("user").get().token ?? "";
            headers = { ...headers, Authorization: `Bearer ${token}` };
        }

        return {
            method: trigger.method || "GET",
            url: `${typeof trigger.pathMapper === "string"
                ? trigger.pathMapper : trigger.pathMapper(state, ctx)}`,
            // headers: state.mapProp("mary", u => u.map(authHeader).getOpt()),
            headers,
            withCredentials: true,
            responseType: trigger.responseType,
            params: !trigger.paramsMapper ? undefined : trigger.paramsMapper(state, ctx),
            data: !trigger.bodyMapper ? undefined : trigger.bodyMapper(state, ctx),
        };
    };
