import * as React from "react";
import { joinClasses } from "../../../utils/Filters";
import { ThemeConsumerProps } from "../../../theme/_Types";

export enum Headings {
    H1 = "h1",
    H2 = "h2",
    H3 = "h3",
    H4 = "h4",
    H5 = "h5",
    H6 = "h6",
}

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    level?: Headings;
    headingStyle?: Headings;
}

export const Heading: React.FC<Props> = (props) => {
    const headingName = (props.level || "span").toLowerCase();

    const clazzName = joinClasses(
        props.headingStyle ? "scl-b-h scl-b-" + props.headingStyle : undefined,
        props.className,
    );

    return (
        React.createElement(
            `${headingName}`,
            { className: clazzName },
            props.children,
        )
    );
};
