import * as React from "react";

import { ThemeConsumerProps } from "../../../../theme/_Types";
import { asThemeConsumer } from "../../../../theme/Theme";
import { joinClasses } from "../../../../utils/Filters";

/**
 *
 */
export interface FlexColumnProps
    extends ThemeConsumerProps {
    isPage?: boolean;
    isFixed?: boolean;
    hasScrollable?: boolean;
}

/**
 *
 * @param props
 */
const FlexColumnComp: React.FunctionComponent<FlexColumnProps> = (props) => {
    const appColumnClassName = "scl-b-app__column";
    const pageClassName = (!!props.isPage ? "scl-b-app__column--page" : undefined);
    const isFixedClassName = (!!props.isFixed ? "scl-b-app__column--fixed" : undefined);
    const hasScrollableClassName = (!!props.hasScrollable ? "scl-b-app__column--has-scrollable" : undefined);

    const className = joinClasses(
        props.className || "",
        "scl-h-background",
        appColumnClassName,
        pageClassName,
        isFixedClassName,
        hasScrollableClassName);

    return (
        <div
            style={props.style}
            className={className}
        >
            {props.children}
        </div>
    );
};

/**
 *
 */
export const FlexColumn = asThemeConsumer(FlexColumnComp);
