import * as React from "react";
import { ThemeConsumerProps, ThemePalette } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { Button } from "../05-atoms/Button";


interface State {
    show: boolean;
}

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    trigger?: React.ReactNode;
    content: React.ReactNode;
}

export class ToggleMenuItemsComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {
            show: false,
        };

        this.onToggle = this.onToggle.bind(this);
    }

    /**
     *
     */
    public render(): JSX.Element {
        return (
            <>
                {this.props.trigger}
                <Button icon={"chevron-down"}
                    theme={{ palette: ThemePalette.CONTRAST_ACCENT }}
                    link={{onClick: () => this.onToggle()}}
                    className={`scl-o-togglemenuitems__trigger ${this.state.show
                        ? "scl-o-togglemenuitems__trigger--open" : "scl-o-togglemenuitems__trigger--closed"}`} />
                <div className={(this.state.show ? "scl-o-togglemenuitems--open"
                    : "scl-o-togglemenuitems--closed")}>
                    {this.props.content}
                </div>
            </>
        );
    }

    private onToggle = () => {
        this.setState({
            show: !this.state.show,
        });
    };
}

/**
 *
 */
export const ToggleMenuItems = asThemeConsumer(ToggleMenuItemsComp);
