/* eslint-disable */
import React from "react";
import { showDisclaimer } from "../App/components/09-views/02-pages/Disclaimer";
import { SelectContextModal } from "../App/components/09-views/02-pages/SelectContextModal";
import { Modal } from "../components/core/06-molecules/Modal";
import { ThemeSize } from "../theme/_Types";
import { modalShow } from "../utils/redux/ActionTypes";
import { mapCustomersResponse, mapInitResponse } from "./Init/RemoteConfig";
import { remoteClearError, remoteClearResponse, remoteTrigger } from "./RemoteActions";
import {
    RemoteConfig,
    RemoteScope,
} from "./RemoteTypes";
import { mapKPIsPerCustomerResponse, mapKPIsResponse } from "./KPIs/RemoteConfig";
import { updateSelectedRole } from "./Init/UpdateSelectedRole";
import { getSelectedRoleFromStateOrLocalStorage } from "./AppTypes";

/**
 *
 */
export const RemoteConfigs: { [K in RemoteScope]: RemoteConfig<K>; } = {
    [RemoteScope.INIT]: {
        scope: RemoteScope.INIT,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.INIT));
            d(remoteClearError(RemoteScope.INIT));
        },
        pathMapper: () => "/base",
        resMapper: mapInitResponse,
        onSuccess: (d, s, _ctx) => {
            const roles = s.prop("remote")?.prop(RemoteScope.INIT)?.roles;
            const userId = s.prop("remote")?.prop(RemoteScope.INIT)?.email;
            if ((roles && roles?.length > 1) && userId) {
                const selectedRole = getSelectedRoleFromStateOrLocalStorage(s);
                if (selectedRole && roles.map(r => r.id).indexOf(selectedRole) !== -1) {
                    showDisclaimer(d, userId);
                } else {
                    d(
                        modalShow(
                            "role-modal",
                            () => (
                                <Modal
                                    modalID={"role-modal"}
                                    theme={{ size: ThemeSize.SMALL }}
                                >
                                    <SelectContextModal modalID={"role-modal"} />
                                </Modal>
                            ),
                        ),
                    );
                    showDisclaimer(d, userId);
                }
            } else {
                const role = s.prop("remote").prop(RemoteScope.INIT)?.roles[0]?.id;
                if(role && userId) {
                    d(updateSelectedRole(role));
                    showDisclaimer(d, userId);
                } else {
                    console.warn("No role and/or user available!");
                }
            }
        },
    },
    [RemoteScope.CUSTOMERS]: {
        scope: RemoteScope.CUSTOMERS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.CUSTOMERS));
            d(remoteClearError(RemoteScope.CUSTOMERS));
        },
        pathMapper: () => "/customers",
        resMapper: mapCustomersResponse,
    },
    [RemoteScope.KPIS]: {
        scope: RemoteScope.KPIS,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.KPIS));
            d(remoteClearError(RemoteScope.KPIS));
        },
        pathMapper: () => "/kpis",
        resMapper: mapKPIsResponse,
    },
    [RemoteScope.UPDATE_KPIS]: {
        scope: RemoteScope.UPDATE_KPIS,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.UPDATE_KPIS));
            d(remoteClearError(RemoteScope.UPDATE_KPIS));
        },
        pathMapper: () => "/kpis",
        resMapper: mapKPIsResponse,
        bodyMapper: (_s, ctx) => ctx.kpisArray,
        onSuccess: (d, _s, _ctx) => {
            d(remoteTrigger(RemoteScope.KPIS, undefined));
        },
    },
    [RemoteScope.DELETE_KPI]: {
        scope: RemoteScope.DELETE_KPI,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.DELETE_KPI));
            d(remoteClearError(RemoteScope.DELETE_KPI));
        },
        method: "DELETE",
        pathMapper: (_s, ctx) => {
            const kpiId = ctx.kpiId;
            return `/kpis/${kpiId}`;
        },
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(
                RemoteScope.KPIS, undefined));
        },
    },
    [RemoteScope.KPIS_PER_CUSTOMER]: {
        scope: RemoteScope.KPIS_PER_CUSTOMER,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.KPIS_PER_CUSTOMER));
            d(remoteClearError(RemoteScope.KPIS_PER_CUSTOMER));
        },
        pathMapper: (s, _ctx) => {
            const customerId = s.prop("userContext")?.customer?.id;
            if (!customerId) {
                throw Error ("Sorry, you should not load this");
            }
            return `/customers/${customerId}/kpis`;
        },
        resMapper: mapKPIsPerCustomerResponse,
        onSuccess: (d, _s, ctx) => {
           if(ctx.callback) {
                ctx?.callback()
            };
        },
    },
    [RemoteScope.UPDATE_KPIS_PER_CUSTOMER]: {
        scope: RemoteScope.UPDATE_KPIS_PER_CUSTOMER,
        method: "POST",
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.UPDATE_KPIS_PER_CUSTOMER));
            d(remoteClearError(RemoteScope.UPDATE_KPIS_PER_CUSTOMER));
        },
        pathMapper: (s, _ctx) => {
            const customerId = s.prop("userContext")?.customer?.id;
            return `/customers/${customerId}/kpis`;
        },
        resMapper: mapKPIsPerCustomerResponse,
        bodyMapper: (_s, ctx) => ctx.kpisArray,
    },
    [RemoteScope.DELETE_KPI_PER_CUSTOMER]: {
        scope: RemoteScope.DELETE_KPI_PER_CUSTOMER,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.KPIS_PER_CUSTOMER));
            d(remoteClearError(RemoteScope.KPIS_PER_CUSTOMER));
            d(remoteClearResponse(RemoteScope.DELETE_KPI_PER_CUSTOMER));
            d(remoteClearError(RemoteScope.DELETE_KPI_PER_CUSTOMER));
        },
        method: "DELETE",
        pathMapper: (s, ctx) => {
            const kpiId = ctx.kpiId;
            const customerId = s.prop("userContext")?.customer?.id;
            return `/customers/${customerId}/kpis/${kpiId}`;
        },
        resMapper: _r => ({}),
        onSuccess: (d, _s, ctx) => {
            d(remoteTrigger(
                RemoteScope.KPIS_PER_CUSTOMER, {callback: ctx.callback}));
        },
    },
};
