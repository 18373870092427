import * as React from "react";
/**
 *
 * @param props
 */
export const NavbarIcon: React.FunctionComponent<{}> = (props) => {
    const children = React.Children.map(props.children, child =>
        React.cloneElement(child as React.ReactHTMLElement<any>, {
            className: "scl-o-navbar__icon",
        }));

    return (
        <div className="scl-o-navbar__nav">
            {children}
        </div>
    );
};
