// import { BreakpointBinding } from "src/mary-lib/utils/Breakpoints";
// import { Orientation } from "src/components/07-molecules/ButtonsWrapper";
// import { ThemeBreakpoints } from "src/mary-lib/theme/_Types";
// import { Opt } from "src/mary-lib/utils/Optional";

import { Orientation } from "../components/core/06-molecules/ButtonsWrapper";
import { ThemeBreakpoints } from "../theme/_Types";
import { BreakpointBinding } from "./Breakpoints";
import { Opt } from "./Optional";

type OrientationMappings = Partial<Record<ThemeBreakpoints, Orientation>>;

export interface Props {
    orientations?: OrientationMappings;
    defaultOrientation?: Orientation;
}

/**
 *
 * @param value
 */

export const getOrientation = (value: BreakpointBinding, orientations?: OrientationMappings): Opt<Orientation> => {
    if (!orientations) {
        return undefined;
    }

    if (value.breakpointAtXL && !!orientations[ThemeBreakpoints.XL]) {
        return orientations[ThemeBreakpoints.XL];
    }
    if (value.breakpointAtLG && !!orientations[ThemeBreakpoints.LG]) {
        return orientations[ThemeBreakpoints.LG];
    }
    if (value.breakpointAtMD && !!orientations[ThemeBreakpoints.MD]) {
        return orientations[ThemeBreakpoints.MD];
    }
    if (value.breakpointAtSM && !!orientations[ThemeBreakpoints.SM]) {
        return orientations[ThemeBreakpoints.SM];
    }
    if (value.breakpointAtXS && !!orientations[ThemeBreakpoints.XS]) {
        return orientations[ThemeBreakpoints.XS];
    }

    return undefined;
};
