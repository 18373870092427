import { OrderedMap } from "immutable";
import * as React from "react";

/**
 *
 */
const misuse = new Error("misuse error: no ModalProvider in ancestors");

/**
 *
 */
export type ModalEntry =
    React.FunctionComponent<{}> |
    React.ComponentClass<{}>;

/**
 *
 */
interface State {
    modals: OrderedMap<string, ModalEntry>;
}

/**
 *
 */
export interface ModalWrapperBinding {
    showModal: (id: string, fn: ModalEntry) => void;
    closeModal: (id: string) => void;
}

/**
 *
 */
export const modalWrapperBindingInit:
() => ModalWrapperBinding =
    () => ({
        showModal: () => {
            throw misuse;
        },
        closeModal: () => {
            throw misuse;
        },
    });

/**
 *
 */
const ModalWrapperContext:
React.Context<ModalWrapperBinding> =
    React.createContext(modalWrapperBindingInit());

/**
 *
 */
export class ModalWrapperProvider
    extends React.Component<{}, State> {

    /**
     *
     * @param props
     */
    public constructor(props: {}) {
        super(props);

        this.state = {
            modals: OrderedMap(),
        };

        this.showModal = this.showModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    /**
     *
     */
    public render() {
        const show = this.showModal;
        const close = this.closeModal;

        return (
            <ModalWrapperContext.Provider
                value={{
                    showModal: show,
                    closeModal: close,
                }}
            >
                <React.Fragment>
                    {this.props.children}
                </React.Fragment>
                <React.Fragment>
                    {/* FIXME(eddy): consider not inlining this to create a less memory-consuming mapping */}
                    {this.state.modals
                        .mapEntries(([k, Clazz]) => [k, <Clazz key={k} />])
                        .toList()
                        .toArray()}
                </React.Fragment>
                {/* TODO show modals here */}
            </ModalWrapperContext.Provider>
        );
    }

    /**
     *
     * @param handle
     * @param me
     */
    private showModal(handle: string, me: ModalEntry) {
        // TODO
        const modals = this.state.modals;
        this.setState({
            modals: modals.set(handle, me),
        });
        return handle;
    }

    /**
     *
     * @param handle
     */
    private closeModal(handle: string) {
        const modals = this.state.modals;
        this.setState({
            modals: modals.delete(handle),
        });
    }
}

/**
 *
 */
export const ModalWrapperConsumer = ModalWrapperContext.Consumer;
