import { Opt } from "../utils/Optional";

export enum Themes {
    CLAUDE = "claude",
    MAN = "man",
    VWPFS = "vwpfs",
    VW = "vw",
    SEAT = "seat",
    SKODA = "skoda",
    AUDI = "audi",
    DUTCHLEASE = "dutchlease",
    XLLEASE = "xllease",
    POLITIE = "politie",
}

export enum ThemeVariants {
    BRAND = "brand",
    CONTRAST = "contrast",
    SHADED = "shaded",
}

/**
 *
 */
export enum ThemeBreakpoints {
    XS = 320,
    SM = 768,
    MD = 1024,
    LG = 1366,
    XL = 1367,
}

/**
 *
 */
export enum ThemePalette {
    BRAND_ACCENT = "brand-accent",
    BRAND_PRIMARY = "brand-primary",
    BRAND_SECONDARY = "brand-secondary",
    BRAND_TERTIARY = "brand-tertiary",
    CONTRAST_ACCENT = "contrast-accent",
    CONTRAST_PRIMARY = "contrast-primary",
    CONTRAST_SECONDARY = "contrast-secondary",
    CONTRAST_TERTIARY = "contrast-tertiary",
    BRAND_SPECIAL_FIRST = "special-brand-first",
    BRAND_SPECIAL_SECOND = "special-brand-second",
    BRAND_SPECIAL_THIRD = "special-brand-third",
    CONTRAST_SPECIAL_FIRST = "special-contrast-first",
    CONTRAST_SPECIAL_SECOND = "special-contrast-second",
    CONTRAST_SPECIAL_THIRD = "special-contrast-third",
}

export enum ThemePaletteShade {
    LIGHTEST = "lightest",
    VERY_LIGHT = "very-light",
    LIGHT = "light",
    MEDIUM = "medium",
    DARK = "dark",
    VERY_DARK = "very-dark",
    DARKEST = "darkest",
}

/**
 *
 */
export enum ThemePaletteState {
    DANGER = "danger",
    WARNING = "warning",
    SUCCESS = "success",
    DISABLED = "disabled",
}

/**
 *
 */
export enum ThemeSize {
    TINY = "tiny",
    SMALL = "small",
    NORMAL = "normal",
    BIG = "big",
    HUGE = "huge",
}

/**
 * TODO(nz,ed): extends ThemeSize?
 */
export enum ThemeShadowSizes {
    NONE = "none",
    TINY = "tiny",
    SMALL = "small",
    NORMAL = "normal",
    BIG = "big",
    HUGE = "huge",
}

/**
 *
 */
export enum ThemeAnimations {
    GROW_IN = "grow-in",
    GROW_IN_OUT_REPEAT = "grow-in-out-repeat",
    SLIDE_IN_LEFT = "slide-in-left",
    SLIDE_IN_RIGHT = "slide-in-right",
    SLIDE_FADE_IN_RIGHT = "slide-fade-in-right",
}

/**
 * Theme provider
 */
export type SpacingValues = Partial<Readonly<{
    l: number;
    r: number;
    t: number;
    b: number;
    x: number;
    y: number;
}>>;

/**
 *
 */
export type ThemeSpacingsProps =
    Partial<Readonly<{
        "": SpacingValues;
        xs: SpacingValues;
        sm: SpacingValues;
        md: SpacingValues;
        lg: SpacingValues;
        xl: SpacingValues;
    }>> |
    number;

/**
 *
 */
export interface ThemeProviderProps {
    margin?: ThemeSpacingsProps;
    padding?: ThemeSpacingsProps;
    palette?: ThemePalette;
    paletteShade?: ThemePaletteShade;
    paletteState?: ThemePaletteState;
    animation?: ThemeAnimations;
    shadow?: ThemeShadowSizes;
    size?: ThemeSize;
}

/**
 * Theme consumer
 */
export interface ThemeConsumerProps {
    className?: string;
    style?: React.CSSProperties;
}

/**
 *
 */
export interface ThemeProps<U extends ThemeProviderProps = ThemeProviderProps> {
    readonly theme?: Readonly<Partial<U>>;
}

/**
 *
 */
export type ThemePropsMapper<T extends ThemeProviderProps> =
    Opt<(props: Partial<Readonly<T>>) => string>;
