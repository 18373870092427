import * as React from "react";

import { ThemeBreakpoints } from "../theme/_Types";

/**
 *
 */
export interface BreakpointBinding {
    breakpointAtXS: boolean;
    breakpointAtSM: boolean;
    breakpointAtMD: boolean;
    breakpointAtLG: boolean;
    breakpointAtXL: boolean;
    // breakpoints: Partial<Record<ThemeBreakpoints, boolean>>;
    width: number;
    height: number;
}

/**
 *
 */
export const breakpointBindingInit:
() => BreakpointBinding =
    () => ({
        breakpointAtXS: false,
        breakpointAtSM: false,
        breakpointAtMD: false,
        breakpointAtLG: false,
        breakpointAtXL: false,
        width: 0,
        height: 0,
    });

/**
 *
 */
export const BreakpointContext:
React.Context<BreakpointBinding> =
    React.createContext(breakpointBindingInit());

/**
 *
 */
interface State
    extends BreakpointBinding {
}

/**
 *
 */
export class BreakpointProvider
    extends React.Component<{}, State> {

    /**
     *
     * @param props
     * @param ctx
     */
    public constructor(props: {}, ctx?: {}) {
        super(props, ctx);
        this.state = this.getDimensions();
    }

    /**
     *
     */
    public render() {
        return (
            <BreakpointContext.Provider
                value={{
                    breakpointAtXS: this.state.breakpointAtXS,
                    breakpointAtSM: this.state.breakpointAtSM,
                    breakpointAtMD: this.state.breakpointAtMD,
                    breakpointAtLG: this.state.breakpointAtLG,
                    breakpointAtXL: this.state.breakpointAtXL,
                    width: this.state.width,
                    height: this.state.height,
                }}
            >
                {this.props.children}
            </BreakpointContext.Provider>
        );
    }

    /**
     *
     */
    public componentDidMount() {
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    /**
     *
     */
    public componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));
    }

    /**
     *
     */
    private getDimensions() {
        const w = window;
        const d = document;
        const documentElement = d.documentElement;
        const body = d.getElementsByTagName("body")[0];
        const width = w.innerWidth
            || (!documentElement ? undefined : documentElement.clientWidth)
            || body.clientWidth;
        const height = w.innerHeight
            || (!documentElement ? undefined : documentElement.clientHeight)
            || body.clientHeight;

        const breakpointAtXS: boolean = (width >= ThemeBreakpoints.XS);
        const breakpointAtSM: boolean = (width >= ThemeBreakpoints.SM);
        const breakpointAtMD: boolean = (width >= ThemeBreakpoints.MD);
        const breakpointAtLG: boolean = (width >= ThemeBreakpoints.LG);
        const breakpointAtXL: boolean = (width >= ThemeBreakpoints.XL);

        return { breakpointAtXS, breakpointAtSM, breakpointAtMD, breakpointAtLG, breakpointAtXL, width, height };
    }

    /**
     *
     */
    private updateDimensions() {
        this.setState(this.getDimensions());
    }
}

/**
 *
 */
export const BreakpointConsumer = BreakpointContext.Consumer;
