/* eslint-disable */
import * as React from "react";
import { Div } from "../03-base/Div";
import { ThemeConsumerProps, ThemePalette, ThemeShadowSizes } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

interface OwnProps {
    content?: string;
    raw?: boolean;
}

/**
 *
 */
type Props = OwnProps & ThemeConsumerProps;

const renderers: {
    [nodeType: string]: React.ElementType<any>;
} = {
    table: (data) => (
        <div className="scl-m-table__wrapper">
            <table className="scl-m-table">{data.children}</table>
        </div>
    ),
    thead: (data) => <thead className="scl-m-table__head">{data.children}</thead>,
    tbody: (data) => <tbody className="scl-m-table__body">{data.children}</tbody>,
    tr: (data) => <tr className="scl-m-table__tr">{data.children}</tr>,
    th: (data) => <th className="scl-m-table__th">{data.children}</th>,
    td: (data) => <td className="scl-m-table__td">{data.children}</td>,
};

/**
 *
 * @param props
 */
export const Markdown: React.FunctionComponent<Props> = (props) => (
    props.raw ? (
        <Div
            theme={{
                shadow: ThemeShadowSizes.TINY,
                palette: ThemePalette.CONTRAST_PRIMARY,
            }}
        >
            <pre className="scl-o-rte__pre">
                {props.content}
            </pre>
        </Div>
    ) : props.content ?
        (
            <ReactMarkdown
                children={props.content}
                remarkPlugins={[remarkGfm]}
                className="scl-o-rte"
                components={renderers}
            />) : <Div className="scl-o-rte">{props.children}</Div>
);

/**
 *
 */
export const RTE = asThemeConsumer(Markdown);
