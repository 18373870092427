import * as React from "react";

import {
    ThemeConsumerProps,
    ThemePaletteState,
} from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { joinClasses } from "../../../utils/Filters";
import { Icon, IconNames } from "./Icon";

/**
 *
 */
const createPlaceholderChildren = () => {
    const placeholderChildren = [];
    for (let i = 2; i < 14; i++) {
        placeholderChildren.push(
            <div
                key={`placeholder ${i}`}
                className={`scl-a-loader-placeholder__load-effect le-${i}`}
            />,
        );
    }
    return placeholderChildren;
};

/**
 * LoadingIndications define the types of loading indicators supported by the
 * LoadingIndicator component. They specify the context of the loading operation
 * for which it is to give feedback.
 */
export enum LoadingIndications {
    GLOBAL = "APP",
    DEFAULT = "INDICATOR",
    PLACEHOLDER = "PLACEHOLDER",
}

/**
 *
 */
export interface Props
    extends ThemeConsumerProps {
    type?: LoadingIndications;
}

/**
 *
 * @param props
 */
const LoadingIndicatorComp: React.FunctionComponent<Props> = (props) => {
    // render the correct components
    switch (props.type) {
    case LoadingIndications.DEFAULT:
        const indicatorClassName = `scl-a-loader-indicator ${props.className ?? ""}`;
        return (
            <div className={indicatorClassName}>
                <div className="scl-a-loader-indicator__content">
                    <div className="scl-a-loader-indicator__animation" />
                    <div className="scl-a-loader-indicator__label">{props.children}</div>
                </div>
            </div>
        );

    case LoadingIndications.PLACEHOLDER:
        const placeHolderClassName = "scl-a-loader-placeholder";
        return (
            <div className={placeHolderClassName}>
                <div className="scl-a-loader-placeholder__load-effect-holder">
                    {createPlaceholderChildren()}
                </div>
            </div>
        );

    case LoadingIndications.GLOBAL:
        const appClassName = joinClasses(
            "scl-a-loader-app",
            props.className,
        );
        return (
            <div className={appClassName}>
                <div className="scl-a-loader-app__content scl-h-animation--grow-in-out-repeat">
                    <Icon
                        name={IconNames.BOLT}
                        theme={{ paletteState: ThemePaletteState.WARNING }}
                        className={"scl-a-loader-app__content--icon"}
                    />
                </div>
            </div>
        );

    default:
        return null;
    }
};

/**
 *
 */
export const LoadingIndicator = asThemeConsumer(LoadingIndicatorComp);
