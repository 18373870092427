import * as React from "react";

import { ThemeConsumerProps } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { joinClasses } from "../../../utils/Filters";

/**
 *
 */
export type GridValues =
    Readonly<Partial<{
        xs: number;
        sm: number;
        md: number;
        lg: number;
        xl: number;
    }>> |
    number;

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    size?: GridValues;
    offset?: GridValues;
}

/**
 *
 * @param prefix
 * @param size
 */
const createGridColumnClass = (prefix: string, size?: GridValues): string => {
    const defaultGridClassName = "scl-b-col-";

    if (!size) {
        return "";
    }

    if (typeof size === "number") {
        return `${defaultGridClassName}${prefix}${size}`;
    }

    return Object.keys(size).reduce(
        (value, bp) => {
            const bpKey: keyof Readonly<Partial<{
                xs: number;
                sm: number;
                md: number;
                lg: number;
                xl: number;
            }>> = bp as keyof Readonly<Partial<{
                xs: number;
                sm: number;
                md: number;
                lg: number;
                xl: number;
            }>>;
            // eslint-disable-next-line
            const breakpoint: number | undefined = size[bpKey];
            return `${value} ${defaultGridClassName}${bp}-${prefix}${breakpoint ?? ""}`;
        },
        "",
    );
};

/**
 *
 * @param props
 */
const GridComp: React.FunctionComponent<Props> = (props) => {
    if (!props.children) {
        return null;
    }

    const sizeClassName = createGridColumnClass("", props.size);
    const offsetClassName = createGridColumnClass("offset-", props.offset);

    const className = joinClasses(
        props.className,
        sizeClassName,
        offsetClassName,
    );

    return (
        <div className={className} >
            {props.children}
        </div>
    );
};

/**
 *
 */
export const Grid = asThemeConsumer(GridComp);
