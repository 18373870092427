import * as React from "react";

import { ThemeConsumerProps, ThemePropsMapper, ThemeProviderProps, ThemeSize } from "../../../theme/_Types";
import { asThemeConsumer } from "../../../theme/Theme";
import { joinClasses } from "../../../utils/Filters";

/**
 *
 */
interface ModalThemeProps
    extends ThemeProviderProps {
    size?: ThemeSize;
}

/**
 * mttc implements mapThemeToClass for ModalThemeProps.
 *
 * @param theme the theme property of a themed Modal component.
 */
const mttc: ThemePropsMapper<ModalThemeProps> = (theme) => (
    joinClasses(
        theme && theme.size === ThemeSize.TINY ? "scl-m-modal--tiny" : undefined,
        theme && theme.size === ThemeSize.SMALL ? "scl-m-modal--small" : undefined,
        theme && theme.size === ThemeSize.HUGE ? "scl-m-modal--huge" : undefined,
    )
);

/**
 *
 */
interface Props
    extends ThemeConsumerProps {
    modalID: string;
    inline?: boolean;
}

/**
 *
 * @param props
 */
export const ModalComp: React.FunctionComponent<Props> = (props) => {
    const clazzName = joinClasses(
        "scl-m-modal",
        props.className,
        !!props.inline ? "scl-m-modal--inline" : undefined,
    );

    return (
        <div
            id={props.modalID}
            className={joinClasses(clazzName, "scl-m-modal--active")}
        >
            <div className="scl-m-modal__dialog">
                <div className="scl-m-modal__content scl-h-shadow--huge">
                    {props.children}
                </div>
            </div>
        </div>
    );
};

/**
 *
 */
export const Modal = asThemeConsumer(ModalComp, mttc);
