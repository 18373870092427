import * as React from "react";
import { joinClasses } from "../../../../utils/Filters";

/**
 *
 */
interface Props {
    className?: string;
    isCollapsed?: boolean;
}

/**
 *
 * @param props
 */
export const NavbarStackLeft: React.FunctionComponent<Props> = (props) => {
    const className = joinClasses(
        props.className,
        "scl-o-navbar__stack scl-o-navbar__stack--left",
    );

    return !props.children
        ? null
        : (
            <div className={className}>
                {props.children}
            </div>
        );
};

/**
 *
 * @param props
 */
export const NavbarStackContent: React.FunctionComponent<Props> = (props) => {
    const className = joinClasses(
        props.className,
        "scl-o-navbar__stack--content",
    );

    return !props.children
        ? null
        : (
            <div className={className}>
                {props.children}
            </div>
        );
};

/**
 *
 * @param props
 */
export const NavbarStackRight: React.FunctionComponent<Props> = (props) => {
    const className = joinClasses(
        props.className,
        "scl-o-navbar__stack",
        props.isCollapsed ? "scl-o-navbar__stack--collapsed" : "scl-o-navbar__stack--right",
    );

    return !props.children
        ? null
        : (
            <div className={className}>
                {props.children}
            </div>
        );
};

/**
 *
 * @param props
 */
export const NavbarStack: React.FunctionComponent<Props> = (props) => {
    const navbarClassName = "scl-o-navbar__stack";

    if (!props.children) {
        throw new Error("missing children in NavbarStack");
    }

    const children = (Array.isArray(props.children) ? props.children : Array.of(props.children));

    /// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    /// WARNING THE FOLLOWING IMPLEMENTATION RELIES ON IMPLEMENTATION DETAILS OF
    /// REACT. MAY BREAK IN FUTURE VERSIONS. PROCEED WITH CAUTION. HERE BE
    /// DRAGONS.
    /// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    const hasLeft = children.findIndex((child) => !!child && (child as { type: {} }).type === NavbarStackLeft) > -1;
    const hasRight = children.findIndex((child) => !!child && (child as { type: {} }).type === NavbarStackRight) > -1;
    /// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    /// WE DID WARN YOU...
    /// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    const navbarLRClassName =
        !hasLeft && !hasRight
            ? undefined
            : [
                "scl-o-navbar--with-nav",
                hasLeft ? "left" : undefined,
                hasRight && hasLeft ? "and" : undefined,
                hasRight ? "right" : undefined,
            ]
                .filter((e) => !!e)
                .join("-");

    const className = joinClasses(
        props.className,
        navbarClassName,
        navbarLRClassName,
    );

    return (
        <div className={className}>
            {props.children}
        </div>
    );
};
