import * as React from "react";
import { ThemeConsumerProps, ThemeProps } from "../../../theme/_Types";
import { joinClasses } from "../../../utils/Filters";
import { Div } from "../03-base/Div";

/**
 *
 */
interface BadgeThemeProps
    extends ThemeProps {
}

/**
 *
 */
export type Props = BadgeThemeProps & ThemeConsumerProps;

/**
 *
 * @param props
 */
export const Badge: React.FunctionComponent<Props> = (props) => {
    const clazzName = joinClasses(
        "scl-a-badge",
        props.className,
    );
    return !props.children
        ? null
        : (
            <Div
                theme={props.theme}
                className={clazzName}
            >
                {props.children}
            </Div>
        );
};


export const testUtils = {
    badgeComp: Badge,
};
