import { Action } from "redux";
import { ThunkAction } from "redux-thunk";

import { FlexWrapperFocus } from "../../components/core/03-base/FlexWrapper";
import { ModalEntry } from "../../components/core/06-molecules/ModalWrapper";
import { MaryBinding, MaryState, maryOf } from "./MaryProvider";
import { State } from "./State";

/**
 *
 */
export const MaryActionPrefix = "Mary/";

/**
 *
 */
export enum MaryActionTypeKeys {
    MARY_INIT = "MARY/INIT",
    MARY_UPDATE = "MARY/UPDATE",
    MARY_UNLINK = "MARY/UNLINK",
    MARY_FLEX_SET_FOCUS = "MARY/FLEX_SET_FOCUS",
    MARY_MODAL_SET_ACTIVE = "MARY/MODAL_SET_ACTIVE",
    MARY_MODAL_DISABLE_ACTIVE = "MARY/MODAL_DISABLE_ACTIVE",
}

/**
 *
 */
export type MaryInitAction = Readonly<
Action<MaryActionTypeKeys.MARY_INIT> &
MaryBinding
>;

/**
 *
 */
export type MaryUpdateAction = Readonly<
Action<MaryActionTypeKeys.MARY_UPDATE> &
MaryBinding
>;

/**
 *
 */
export type MaryUnlinkAction = Readonly<
Action<MaryActionTypeKeys.MARY_UNLINK> & {}>;

/**
 *
 */
export type MaryFlexSetFocusAction = Readonly<
Action<MaryActionTypeKeys.MARY_FLEX_SET_FOCUS> & {
    focus: FlexWrapperFocus;
}>;

/**
 *
 */
export type MaryModalShowAction = Readonly<
Action<MaryActionTypeKeys.MARY_MODAL_SET_ACTIVE> & {
    id: string;
    me: ModalEntry;
}>;

/**
 *
 */
export type MaryModalCloseAction = Readonly<
Action<MaryActionTypeKeys.MARY_MODAL_DISABLE_ACTIVE> & {
    id: string;
}>;

/**
 *
 */
export const maryInit:
(props: MaryBinding) => MaryInitAction =
    (props) => ({
        type: MaryActionTypeKeys.MARY_INIT,
        ...props,
    });

/**
 *
 * @param props
 */
export const maryUpdate:
(props: MaryBinding) => MaryUpdateAction =
    (props) => ({
        type: MaryActionTypeKeys.MARY_UPDATE,
        ...props,
    });

/**
 *
 */
export const maryUnlink:
() => MaryUnlinkAction =
    () => ({
        type: MaryActionTypeKeys.MARY_UNLINK,
    });

/**
 *
 * @param to
 */
export const flexSetFocus:
<S extends { mary?: MaryState }>
(to: FlexWrapperFocus) => ThunkAction<void, State<S>, undefined, Action<MaryActionTypeKeys>> =
    (to) => ((_dispatch, getState) => {
        maryOf(getState()).prop("flex").prop("setFocus")(to);
    });

/**
 *
 * @param to
 */
export const modalShow:
<S extends { mary?: MaryState }>
(id: string, me: ModalEntry) => ThunkAction<void, State<S>, undefined, Action<MaryActionTypeKeys>> =
    (id, me) => ((_dispatch, getState) => {
        maryOf(getState()).prop("modal").prop("showModal")(id, me);
    });

/**
 *
 */
export const modalClose:
<S extends { mary?: MaryState }>
(id: string) => ThunkAction<void, State<S>, undefined, Action<MaryActionTypeKeys>> =
    (id: string) => ((_dispatch, getState) => {
        maryOf(getState()).prop("modal").prop("closeModal")(id);
    });
