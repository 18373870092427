import { useKeycloak } from "@react-keycloak/web";
import * as React from "react";
import { connect } from "react-redux";
import { Button } from "../../../components/core/05-atoms/Button";
import { IconNames } from "../../../components/core/05-atoms/Icon";
import { DispatchFunc } from "../../../store/ActionTypes";
import { signout } from "../../../store/AppActions";
import { ThemePaletteState } from "../../../theme/_Types";

interface DispatchProps {
    signout: () => void;
}

interface OwnProps {
    noIcon?: boolean;
    label?: string;
    onSuccess?: () => void;
}


type Props = DispatchProps & OwnProps;

export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    signout: () => dispatch(signout()),
});

const LogoutBtnComp: React.FC<Props> = (props) => {
    const { keycloak } = useKeycloak();
    return (
        props.noIcon ?
            <Button
                link={{ onClick: () => {
                    props.signout();
                    keycloak?.logout({redirectUri: process.env.REACT_APP_URL}).then(() => {
                        console.log("Iris: Logout success");
                    }).catch(() => {
                        console.log("Iris: Logout failed");
                    });
                    localStorage.clear();
                    props.onSuccess?.();
                } }}
                theme={{paletteState: ThemePaletteState.DANGER}}
            >
                {props.label ? props.label : "Logout"}
            </Button>
            : <Button
                link={{ onClick: () => {
                    props.signout();
                    keycloak?.logout({redirectUri: process.env.REACT_APP_URL}).then(() => {
                        console.log("Iris: Logout success");
                    }).catch(() => {
                        console.log("Iris: Logout failed");
                    });
                    localStorage.clear();
                    props.onSuccess?.();
                } }}
                icon={IconNames.POWER_OFF}
                className={"scl-o-navbar__icon"}
            />
    );
};

/**
 *
 */
export const LogoutBtn = connect(
    undefined,
    mapDispatchToProps,
)(LogoutBtnComp);
