import { connect } from "react-redux";

import { DispatchFunc } from "../../store/ActionTypes";
import { modalClose, modalShow } from "../../utils/redux/ActionTypes";
import { DispatchProps, ModalGenericWarning, ModalNewVersionAvailable, VerifyContext } from "../../utils/VerifyContext";

const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    handleBrowserNotification: (message: string) => dispatch(
        modalShow("genericwarningmodal", () => ModalGenericWarning({
            id: "genericwarningmodal",
            onClose: () => modalClose("genericwarningmodal"), message: message}))),
    handleNewAppVersion: (message: string) => dispatch(
        modalShow("newversionmodal", () => ModalNewVersionAvailable({
            id: "newversionmodal",
            onClose: () => dispatch(modalClose("newversionmodal")),
            message: message,
        }))),
    closeModal: (id: string) => dispatch(modalClose(id)),
});

/**
 *
 */
export const VerifyContextWrapper = connect(
    undefined,
    mapDispatchToProps,
)(VerifyContext);

export const testUtil = {
    mapDispatchToProps: mapDispatchToProps,
};
