/* eslint-disable */
import React from "react";

import { ThemePalette, ThemeShadowSizes } from "../../../theme/_Types";
import { Div } from "../../core/03-base/Div";
import { Badge } from "./Badge";

/**
 *
 */
interface Props {
    apiVersion?: string;
    isCollapsed?: boolean;
    apiLabel?: string;
}

/**
 *
 * @param props
 */
export const ReleaseVersion: React.FC<Props> = (props) => {

    const packageForReleaseVersion = require("../../../../package.json");
    return(
        <Div className="scl-m-release-version">
            <Badge
                theme={{
                    palette: ThemePalette.BRAND_ACCENT,
                    shadow: ThemeShadowSizes.TINY,
                }}
            >
                {packageForReleaseVersion.version}
            </Badge>
            { !!props.apiVersion && !props.isCollapsed &&
                <>
                &nbsp;
                    <Badge
                        theme={{
                            palette: ThemePalette.CONTRAST_TERTIARY,
                            shadow: ThemeShadowSizes.TINY,
                        }}
                    >
                        <span style={{textTransform: "none"}}>{props.apiLabel ? props.apiLabel : "API"}:&nbsp;{props.apiVersion}</span>
                    </Badge>
                </>
            }
        </Div>
    );
};
